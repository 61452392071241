import Config from "../Config";
import axios from "axios";
import Error from './Error';
import Misc from "./Misc";
import camelCase from 'camelcase-keys-deep';

const ErrorService = new Error();
const MiscService = new Misc();

class Payments {

  getPayments = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {}
      payload.page = payload.page || 1
      payload.limit = payload.limit || 1
      payload.order = payload.Order || "ASC"
      let url = `${Config.apiHost}/api/thirdparty/payments?page=${payload.page}&limit=${payload.limit}&order=${payload.order}`
      if (payload.search && payload.search.length > 0 ) {
          url += `&search=${payload.search}`
      }
      axios.get(url, { headers: MiscService.generateHeaders() })
      .then((response) => {
          resolve(response.data);
      })
      .catch((err) => {
        reject(err)
        ErrorService.handle(err)
      })
    });
  }

  checkPayment = (id) => {
    return new Promise((resolve, reject) => {
      id = id || ""
      let url = `${Config.apiHost}/api/check-payment/${id}`
      axios.get(url, { headers: MiscService.generateHeaders() })
      .then((response) => {
          resolve(response.data);
      })
      .catch((err) => {
        reject(err)
        ErrorService.handle(err)
      })
    });  
  }

  getPaymentRecaps = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {}
      payload.page = payload.page || 1
      payload.limit = payload.limit || 1
      payload.order = payload.Order || "ASC"
      let url = `${Config.apiHost}/api/admin/payment-recaps?page=${payload.page}&limit=${payload.limit}&order=${payload.order}`
      if (payload.search && payload.search.length > 0 ) {
          url += `&search=${payload.search}`
      }
      axios.get(url, { headers: MiscService.generateHeaders() })
      .then((response) => {
          resolve(response.data);
      })
      .catch((err) => {
        reject(err)
        ErrorService.handle(err)
      })
    });
  }

}

export default Payments;
