import React, { Component } from 'react';

import {
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageContentBody,
  EuiTitle,
  EuiFieldText,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiPage,
  EuiPageBody,
  EuiFormRow,
  EuiFilePicker,
  EuiSpacer,
  EuiTab,
  EuiTabs,
  EuiSwitch,
  EuiSelect,
  EuiCard,
  EuiIcon,
  EuiLoadingSpinner,
  EuiButtonToggle,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiListGroup,
  EuiComboBox,
  EuiListGroupItem,
  EuiModalFooter,
  EuiGlobalToastList,
  EuiButtonEmpty,
  EuiTextArea,
  EuiHorizontalRule,
} from '@elastic/eui';
import Table from '../components/Table';
import Staff from '../services/Staffs';
import OrganizationService from '../services/Organizations';
import ClinicService from '../services/Clinics';
import ErrorService from '../services/Error.js';
import Utils from '../Utils';
import SessionService from '../services/Sessions';
import TablePage from '../components/TablePage';
import moment from 'moment';
import LogsService from '../services/Logs';
import Config from '../Config';
import printJs from 'print-js';
import ISO6391 from 'iso-639-1';

const staffService = new Staff();
const organizationService = new OrganizationService();
const clinicService = new ClinicService();
const errorService = new ErrorService();
const sessionsService = new SessionService();
const logService = new LogsService();

class StaffDetail extends Component {
  state = {
    isDoctor: true,
    selectedTabId: 'datadiri',
    data: [],
    page: 1,
    limit: 10,
    order: 'ASC',
    orderBy: 'schedule',
    sessionsData: [],
    column: [],
    licenseData: [],
    licenseColumn: [],
    licenseForm: {
      lisensi: '',
      nomorLisensi: '',
      kadaluwarsa: '',
    },
    doctorForm: {
      id: '',
      nameDoctor: '',
      nip: '',
      nik: '',
      specialization: '',
      phone: '',
      email: '',
      clinic: '',
      icon: '',
    },
    staffType: [
      {
        value: 'HOTEL_STAFF',
        text: 'Staff Hotel',
      },
      {
        value: 'DOCTOR',
        text: 'Dokter',
      },
      {
        value: 'NURSE',
        text: 'Asisten',
      },
      {
        value: 'PHARMACY',
        text: 'Staff Farmasi',
      },
      {
        value: '',
        text: 'Pilih tipe staff..',
      },
    ],
    // Staff item
    avatarImage: '',
    clinicId: '',
    clinicName: '',
    emailAddress: '',
    id: '',
    externalId: '',
    name: '',
    organizationId: '',
    organizationName: '',
    staffTypeName: '',
    phoneNumber: '',
    phoneNumbers: [],
    type: '',
    specialization: '',
    nameEn: '',
    specializationEn: '',
    nip: '',
    nik: '',
    intro: '',
    languageSupports: [],
    almameters: [],
    almameter: {
      almameter: '',
      year: '',
      degree: '',
    },
    instant: false,
    clinics: [], // Picked clinics in form of [{id:'', name:''}]
    statusOptions: [
      { text: 'Tersedia', value: 0 },
      { text: 'Tidak tersedia', value: 1 },
      { text: 'Tidak aktif', value: 2 },
    ],
    sessionsColumn: [
      {
        field: 'clinicName',
        name: 'Klinik',
      },
      {
        field: 'patientName',
        name: 'Nama pasien',
      },
      {
        field: 'patientPhoneNumber',
        name: 'Nomor telepon pasien',
      },
      {
        field: 'staffName',
        name: 'Nama staff/dokter',
      },
      {
        field: 'scheduleDate',
        name: 'Tanggal',
      },
      {
        field: 'scheduleTime',
        name: 'Pukul',
      },
      {
        field: 'slotNumber',
        name: 'Urutan',
      },
      {
        field: 'roomId',
        name: 'ID ruangan',
      },
      {
        field: 'id',
        name: 'Log Sesi',
        render: (id) => (
          <EuiButtonToggle
            label='Tampilkan Log'
            iconType='eye'
            onChange={() => {
              this.loadSessionLogsBySession(id);
            }}
            isEmpty
            isIconOnly
          />
        ),
      },
      {
        field: 'id',
        name: 'Tautan',
        render: (id, sessionItem) => (
          <>
            {!moment(sessionItem.scheduleDate).isBefore(
              moment().format('YYYY-MM-DD')
            ) &&
            Config.weblink !== '' &&
            sessionItem.status === 1 ? (
              <a href={'/weblink-list/' + id}>SiapDok link</a>
            ) : null}
          </>
        ),
      },
    ],
    // Staff form
    validationFields: {
      status: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return true;
        },
      },
      externalId: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return true;
        },
      },
      tags: {
        type: 'string',
        isInvalid: false,
        errors: [''],
        isValidFunc: function (value) {
          return true;
        },
      },
      name: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
      nameEn: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return true; // currently no validate
        },
      },
      organizationId: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
      organizationName: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
      phoneNumber: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
      type: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
      nip: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return true; // allowed to be empty
        },
      },
      nik: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return true; // allowed to be empty
        },
      },
      specialization: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return true; // allowed to be empty
        },
      },
      specializationEn: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return true; // allowed to be empty
        },
      },
      sip: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return true; // allowed to be empty
        },
      },
      instant: {
        type: 'bool',
        isInvalid: false,
        errors: [''],
        isValidFunc: function (value) {
          return true; // allowed to be empty
        },
      },
      intro: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return true; // allowed to be empty
        },
      },
      languageSupports: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return true; // allowed to be empty
        },
      },
      almameters: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return true; // allowed to be empty
        },
      },
    },
    toasts: [],
    toastId: 0,
  };

  componentDidMount() {
    let uuid = this.props.match.params.uuid;
    if (!uuid || (uuid && uuid.length < 1)) {
      this.props.history.push('/');
      return;
    }
    if (uuid && uuid === 'new') {
      this.prepareNewItem();
    } else {
      let staff = {};
      staffService
        .getStaff(uuid)
        .then((result) => {
          staff = result;
          let tags = [];
          for (let i in staff.tags) {
            tags.push({ label: staff.tags[i] });
          }
          staff.tags = tags;

          let languageSupports = [];
          for (let i in staff.languageSupports) {
            languageSupports.push({
              value: staff.languageSupports[i],
              label: `${ISO6391.getName(
                staff.languageSupports[i]
              )} (${ISO6391.getNativeName(staff.languageSupports[i])})`,
            });
          }
          staff.languageSupports = languageSupports;

          this.setState(staff);
          if (
            staff.avatarImage &&
            staff.avatarImage.length > 0 &&
            staff.avatarImage !== '00000000-0000-0000-0000-000000000000'
          ) {
            staffService.getFile(staff.avatarImage).then((result) => {
              this.setState({ avatar: result.base64 });
            });
          }
          this.loadClinicOrganizations();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            loading: false,
          });
        });
    }

    this.loadOrganizations();

    // Mocked
    this.dummyData();
    this.initLicenseTable();
  }

  generateYears = () => {
    let years = [
      {
        disabled: true,
        value: '',
        text: 'Pilih tahun...',
      },
    ];
    let min = 1900;
    let max = moment().year();

    for (var i = max; i >= min; i--) {
      years.push({ value: i, label: i });
    }

    return years;
  };

  page = (page) => {
    page = page || 1;
    let payload = {
      page: page,
      limit: this.state.limit,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };
    this.setState({ loading: true });
    sessionsService
      .getSessionsByStaffId(this.state.id, payload)
      .then((result) => {
        this.setState(
          {
            page: result.Page,
            limit: result.Limit,
            total: result.Total,
            order: result.Order,
            orderBy: result.OrderBy,
            sessionsData: result.Items,
            loading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        let obj = {
          sessionsData: [],
          loading: false,
        };
        this.setState(obj);
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  loadSessionLogsBySession = (id) => {
    logService
      .getLogsBySessionId(id)
      .then((result) => {
        for (let i in result.data) {
          result.data[i].timestamp = moment(result.data[i].timestamp).format(
            'YYYY-MM-DD HH:mm'
          );
          switch (result.data[i].type) {
            case 'HEARTBEAT':
              result.data[i].type = 'sedang di dalam sesi';
              break;
            case 'OUT':
              result.data[i].type = 'telah keluar dari sesi';
              break;
            case 'SESSION_STARTED':
              result.data[i].type = 'Sesi telah dimulai';
              break;
            case 'SESSION_ENDED':
              result.data[i].type = 'Sesi telah berakhir';
              break;
            default:
              break;
          }
          switch (result.data[i].userType) {
            case 'PATIENT':
              result.data[i].userType = 'Pasien';
              break;
            case 'DOCTOR':
              result.data[i].userType = 'Dokter';
              break;
            case 'ASSISTANT':
              result.data[i].userType = 'Asisten';
              break;
            default:
              break;
          }
        }
        this.setState({
          showModal: true,
          logsData: result.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      logsData: [],
    });
  };

  printLogs = (payload) => {
    let items = [];
    for (let i in payload) {
      let item = {
        logs: `${payload[i].timestamp} - ${payload[i].userType} ${payload[i].type}`,
      };
      items.push(item);
    }
    printJs({
      printable: items,
      properties: [{ field: 'logs', displayName: 'List logs' }],
      type: 'json',
    });
  };

  loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [];
        if (this.state.isNewItem) {
          options.push({ value: '', text: 'Pilih organisasi...' });
        }
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        this.setState({ organizations: options });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          organizations: [],
        });
      });
  };
  loadClinics = (organizationId) => {
    let payload = {
      limit: 1000,
      organizationId: organizationId,
    };
    clinicService
      .getClinics(payload)
      .then((result) => {
        let options = [];
        for (let i in result.items) {
          let exist = false;
          for (let j in this.state.clinics) {
            if (result.items[i].id === this.state.clinics[j].id) {
              exist = true;
              break;
            }
          }
          if (exist) {
            continue;
          }
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        if (options && options.length < 1) {
          options.splice(0, 0, {
            value: '',
            text: 'Tidak ada klinik tersedia',
          });
        } else {
          options.splice(0, 0, {
            value: '',
            text: 'Pilih klinik..',
          });
        }
        this.setState({ availableClinics: options });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          organizations: [],
        });
      });
  };
  loadClinicOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [];
        options.push({ value: '', text: 'Pilih organisasi...' });
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        this.setState({ availableClinicOrganizations: options });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          availableClinicOrganizations: [],
        });
      });
  };
  handleChange = (e) => {
    let value = e?.target?.value || null;
    let obj = {};
    if (e.target.name === 'phoneNumber' && value !== null) {
      value = value.replace(/[^0-9]/g, '');
    }
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  handleTagsChange = (tags) => {
    this.setState({ tags: tags });
  };

  handleLanguageSupportsChange = (languageSupports) => {
    this.setState({ languageSupports: languageSupports });
  };

  onOrganizationChange = (e) => {
    let value = e.target.value || null;
    let name = '';
    for (let i in this.state.organizations) {
      if (this.state.organizations[i].value === value) {
        name =
          this.state.organizations[i].name || this.state.organizations[i].text;
      }
    }
    this.setState({ organizationId: value, organizationName: name });
    this.loadClinicOrganizations();
  };

  onClinicOrganizationChange = (e) => {
    let value = e.target.value || null;
    let name = '';
    for (let i in this.state.organizations) {
      if (this.state.availableClinicOrganizations[i].value === value) {
        name =
          this.state.availableClinicOrganizations[i].name ||
          this.state.availableClinicOrganizations[i].text;
      }
    }
    this.setState({
      clinicOrganizationId: value,
      clinicOrganizationName: name,
    });
    this.loadClinics(value);
  };

  onStatusChange = (e) => {
    let value = e.target.value || null;
    let name = '';
    for (let i in this.state.statusOptions) {
      if (this.state.statusOptions[i].value === value) {
        name =
          this.state.statusOptions[i].name || this.state.statusOptions[i].text;
      }
    }
    this.setState({ status: value });
  };

  onStaffTypeChange = (e) => {
    let value = e.target.value || null;
    let name = '';
    for (let i in this.state.staffType) {
      if (this.state.staffType[i].value === value) {
        name = this.state.staffType[i].name || this.state.staffType[i].text;
      }
    }
    this.setState({ type: value, staffTypeName: name });
    let obj = {};
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  onPickedClinic = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }
    let name = '';
    for (let i in this.state.availableClinics) {
      if (this.state.availableClinics[i].value === value) {
        name =
          this.state.availableClinics[i].name ||
          this.state.availableClinics[i].text;
      }
    }
    this.setState({ pickedClinicId: value, pickedClinicName: name });
  };

  addClinic = (id, name) => {
    let clinics = this.state.clinics || [];
    let found = false;
    for (let i in clinics) {
      if (clinics[i].id === id) {
        found = true;
        break;
      }
    }
    if (found) {
      return;
    }
    clinics.push({ id: id, name: name });
    this.setState({
      pickedClinicId: '',
      pickedClinicName: '',
      clinicOrganizationId: '',
      availableClinics: [],
    });
    this.setState({ clinics: clinics });
  };

  dummyData() {
    const data = [];
    const column = [
      {
        field: 'name',
        name: 'name',
      },
      {
        field: 'nip',
        name: 'nip',
      },
      {
        field: 'nik',
        name: 'nik',
      },
      {
        field: 'specialization',
        name: 'specialization',
      },
      {
        field: 'email',
        name: 'email',
      },
      {
        field: 'location',
        name: 'location',
      },
      {
        field: 'account',
        name: 'account',
      },
      {
        field: 'date',
        name: 'date',
      },
      {
        field: 'amount',
        name: 'amount',
      },
      {
        field: 'phone',
        name: 'phone',
      },
      {
        field: 'version',
        name: 'version',
      },
    ];
    for (let index = 0; index < 50; index++) {
      data.push({
        id: String(index),
        name: String(index) + 'name',
        nip: String(index) + 'nip',
        nik: String(index) + 'nik',
        specialization: String(index) + 'specialization',
        instant: String(index) + 'instant',
        email: String(index) + 'email',
        location: String(index) + 'location',
        account: String(index) + 'account',
        date: String(index) + 'date',
        amount: String(index) + 'amount',
        phone: String(index) + 'phone',
        version: String(index) + 'version',
      });
    }

    this.setState({ data, column });
  }

  initLicenseTable() {
    const column = [
      {
        field: 'lisensi',
        name: 'Lisensi',
      },
      {
        field: 'nomorLisensi',
        name: 'Nomor',
      },
      {
        field: 'kadaluwarsa',
        name: 'Kadaluwarsa',
      },
      {
        field: 'action',
        name: 'Aksi',
      },
    ];

    this.setState({ licenseColumn: column });
  }

  onSelectedTabChanged = (id) => {
    this.setState({ selectedTabId: id });
    if (id === 'pertemuan') {
      this.page(1);
    }
  };

  onAvatarChange = (files) => {
    if (files.length < 1) {
      this.setState({ avatar: null });
      return;
    }
    this.setState(
      {
        avatarLoading: true,
      },
      () => {
        staffService
          .uploadAvatarFile(files[0])
          .then((result) => {
            this.setState({ avatarImage: result.blobId });
            return staffService.getFile(result.blobId);
          })
          .then((result) => {
            this.setState({ avatar: result, avatarLoading: false });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  renderTabs = () => {
    const tabs = !this.state.isDoctor
      ? [
          {
            id: 'datadiri',
            name: 'Data Diri',
            disabled: false,
          },
          {
            id: 'lisensi',
            name: 'Lisensi',
            disabled: false,
          },
          {
            id: 'pertemuan',
            name: 'Pertemuan',
            disabled: false,
          },
        ]
      : [
          {
            id: 'datadiri',
            name: 'Data Diri',
            disabled: false,
          },
          {
            id: 'lisensi',
            name: 'Lisensi',
            disabled: false,
          },
          {
            id: 'pertemuan',
            name: 'Pertemuan',
            disabled: false,
          },
          {
            id: 'pendapatan',
            name: 'Pendapatan',
            disabled: false,
          },
        ];
    return tabs.map((tab, index) => (
      <EuiTab
        {...(tab.href && { href: tab.href, target: '_blank' })}
        onClick={() => this.onSelectedTabChanged(tab.id)}
        isSelected={tab.id === this.state.selectedTabId}
        disabled={tab.disabled}
        key={index}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  setAll = (obj, val) => Object.keys(obj).forEach((k) => (obj[k] = val));
  setNull = (obj) => this.setAll(obj, '');

  doctorFormValueChanger(key, value) {
    let tempData = this.state.doctorForm;
    tempData[key] = value;
    this.setState({ doctorForm: tempData });
  }

  licenseFormValueChanger(key, value) {
    let tempData = this.state.licenseForm;
    tempData[key] = value;
    this.setState({ licenseForm: tempData });
  }

  licenseFormEmptier() {
    let tempData = this.state.licenseForm;
    this.setNull(tempData);
    this.setState({ licenseForm: tempData });
  }

  renderTabsContent = () => {
    switch (this.state.selectedTabId) {
      case 'datadiri':
        return (
          <EuiPageContentBody>
            <EuiForm className='form-layout' id='formDokter'>
              {!this.state.isNewItem && (
                <EuiFormRow label='ID'>
                  <EuiFieldText
                    name='idDokter'
                    disabled
                    value={this.state.id}
                  />
                </EuiFormRow>
              )}
              {!this.state.isNewItem && (
                <EuiFormRow
                  label='External ID'
                  isInvalid={
                    this.state.validationFields['externalId'].isInvalid
                  }
                  error={
                    this.state.validationFields['externalId'].isInvalid &&
                    this.state.validationFields['externalId'].errors
                  }
                >
                  <EuiFieldText
                    name='externalId'
                    placeholder='External ID'
                    onChange={this.handleChange}
                    aria-label='External ID'
                    value={this.state.externalId}
                  />
                </EuiFormRow>
              )}
              {this.state.isNewItem && (
                <EuiFormRow
                  label='Tipe Staff'
                  isInvalid={this.state.validationFields['type'].isInvalid}
                  error={
                    this.state.validationFields['type'].isInvalid &&
                    this.state.validationFields['type'].errors
                  }
                >
                  <EuiSelect
                    placeholder='Staff Tipe'
                    options={this.state.staffType}
                    value={this.state.type}
                    name='type'
                    onChange={this.onStaffTypeChange}
                    aria-label='staffType'
                  />
                </EuiFormRow>
              )}

              {!this.state.isNewItem && (
                <EuiFormRow
                  label='Status'
                  isInvalid={this.state.validationFields['status'].isInvalid}
                  error={
                    this.state.validationFields['status'].isInvalid &&
                    this.state.validationFields['status'].errors
                  }
                >
                  <EuiSelect
                    placeholder='Status'
                    options={this.state.statusOptions}
                    value={this.state.status}
                    name='organizationName'
                    onChange={this.onStatusChange}
                    aria-label='Status'
                  />
                </EuiFormRow>
              )}
              <EuiFormRow
                label='Tag'
                isInvalid={this.state.validationFields['tags'].isInvalid}
                error={
                  this.state.validationFields['tags'].isInvalid &&
                  this.state.validationFields['tags'].errors
                }
              >
                <EuiComboBox
                  placeholder='Tag'
                  options={[]}
                  label={'Tag'}
                  isClearable={true}
                  selectedOptions={this.state.tags || []}
                  name='Tag'
                  onChange={this.handleTagsChange}
                  onCreateOption={(val) => {
                    console.log(val);
                    let tags = this.state.tags || [];
                    tags.push({ label: val });
                    this.setState({ tags: tags });
                  }}
                  aria-label='Tag'
                />
              </EuiFormRow>
              <EuiFormRow
                label='Nama'
                isInvalid={this.state.validationFields['name'].isInvalid}
                error={
                  this.state.validationFields['name'].isInvalid &&
                  this.state.validationFields['name'].errors
                }
              >
                <EuiFieldText
                  placeholder='Nama'
                  value={this.state.name}
                  name='name'
                  onChange={this.handleChange}
                  aria-label='Nama'
                />
              </EuiFormRow>
              <EuiFormRow
                label='Nama Dalam Bahasa Inggris'
                isInvalid={this.state.validationFields['nameEn'].isInvalid}
                error={
                  this.state.validationFields['nameEn'].isInvalid &&
                  this.state.validationFields['nameEn'].errors
                }
              >
                <EuiFieldText
                  placeholder='Nama dalam bahasa inggris'
                  value={this.state.nameEn}
                  name='nameEn'
                  onChange={this.handleChange}
                  aria-label='Nama EN'
                />
              </EuiFormRow>
              <EuiFormRow
                label='Nomor telepon'
                isInvalid={this.state.validationFields['phoneNumber'].isInvalid}
                helpText={
                  <a href="#/" onClick={() => {let phoneNumbers = this.state.phoneNumbers || []; phoneNumbers.push({}); this.setState({ phoneNumbers: phoneNumbers });
                 }}
                  >
                    Tambah nomor telepon
                  </a>
                }
                error={
                  this.state.validationFields['phoneNumber'].isInvalid &&
                  this.state.validationFields['phoneNumber'].errors
                }
              >
                <div>
                  
                  <EuiFieldText
                    placeholder='Nomor telepon utama'
                    value={this.state.phoneNumber}
                    name='phoneNumber'
                    onChange={this.handleChange}
                    aria-label='Nomor telepon utama'
                  />
                  {this.state.phoneNumbers &&
                    this.state.phoneNumbers.map((item, index) => {
                      return (
                        (!item.tags ||
                          (item.tags && item.tags != 'default')) && (
                          <EuiFieldText
                            key={index}
                            placeholder='Nomor telepon alternatif'
                            value={
                              this.state.phoneNumbers[index].phoneNumber|| ''
                            }
                            
                            name='phoneNumber'
                            onChange={(e) => {
                              let phoneNumbers = this.state.phoneNumbers;
                              if (
                                e.target &&
                                e.target.value &&
                                e.target.value.length > 0
                              ) {
                                phoneNumbers[index].phoneNumber =
                                  e.target.value;
                                this.setState({ phoneNumbers: phoneNumbers });
                              }
                            }}
                            aria-label='Nomor telepon alternatif'
                            append={
                              <EuiButtonEmpty
                                size='xs'
                                iconType='trash'
                                onClick={() => {
                                  let phoneNumbers = this.state.phoneNumbers;
                                  phoneNumbers.splice(index, 1);
                                  this.setState({ phoneNumbers: phoneNumbers });
                                }}
                              ></EuiButtonEmpty>
                            }
                          />
                        )
                      );
                    })}
                </div>
              </EuiFormRow>
              <EuiFormRow
                label='NIP'
                isInvalid={this.state.validationFields['nip'].isInvalid}
                error={
                  this.state.validationFields['nip'].isInvalid &&
                  this.state.validationFields['nip'].errors
                }
              >
                <EuiFieldText
                  placeholder='NIP'
                  value={this.state.nip}
                  name='nip'
                  onChange={this.handleChange}
                  aria-label='NIP'
                />
              </EuiFormRow>
              <EuiFormRow
                label='NIK'
                isInvalid={this.state.validationFields['nik'].isInvalid}
                error={
                  this.state.validationFields['nik'].isInvalid &&
                  this.state.validationFields['nik'].errors
                }
              >
                <EuiFieldText
                  placeholder='NIK'
                  value={this.state.nik}
                  name='nik'
                  onChange={this.handleChange}
                  aria-label='NIK'
                />
              </EuiFormRow>
              {this.state.type !== 'HOTEL_STAFF' && (
                <div style={{ marginTop: 16 }}>
                  <EuiFormRow
                    label='Spesialisasi'
                    isInvalid={
                      this.state.validationFields['specialization'].isInvalid
                    }
                    error={
                      this.state.validationFields['specialization'].isInvalid &&
                      this.state.validationFields['specialization'].errors
                    }
                  >
                    <EuiFieldText
                      placeholder='Spesialisasi'
                      value={this.state.specialization}
                      name='specialization'
                      onChange={this.handleChange}
                      aria-label='Spesialisasi'
                    />
                  </EuiFormRow>
                  <EuiFormRow
                    label='Spesialisasi Dalam Bahasa Inggris'
                    isInvalid={
                      this.state.validationFields['specializationEn'].isInvalid
                    }
                    error={
                      this.state.validationFields['specializationEn']
                        .isInvalid &&
                      this.state.validationFields['specializationEn'].errors
                    }
                  >
                    <EuiFieldText
                      placeholder='Spesialisasi Dalam Bahasa Inggris'
                      value={this.state.specializationEn}
                      name='specializationEn'
                      onChange={this.handleChange}
                      aria-label='Spesialisasi EN'
                    />
                  </EuiFormRow>
                  <EuiFormRow label='Instan'>
                    <EuiSwitch
                      onChange={() => {
                        this.setState({ instant: !this.state.instant });
                      }}
                      checked={this.state.instant}
                    />
                  </EuiFormRow>
                </div>
              )}
              <EuiFormRow
                label='SIP'
                isInvalid={this.state.validationFields['sip'].isInvalid}
                error={
                  this.state.validationFields['sip'].isInvalid &&
                  this.state.validationFields['sip'].errors
                }
              >
                <EuiFieldText
                  placeholder='SIP'
                  value={this.state.sip}
                  name='sip'
                  onChange={this.handleChange}
                  aria-label='sip'
                />
              </EuiFormRow>
              <EuiFormRow label='Surel'>
                <EuiFieldText
                  placeholder='Surel'
                  value={this.state.emailAddress}
                  name='emailAddress'
                  onChange={this.handleChange}
                  aria-label='Surel'
                />
              </EuiFormRow>
              <EuiFormRow
                label='Organisasi'
                isInvalid={
                  this.state.validationFields['organizationId'].isInvalid
                }
                error={
                  this.state.validationFields['organizationId'].isInvalid &&
                  this.state.validationFields['organizationId'].errors
                }
              >
                <EuiSelect
                  placeholder='Organisasi'
                  options={this.state.organizations}
                  value={this.state.organizationId}
                  name='organizationName'
                  onChange={this.onOrganizationChange}
                  aria-label='Organisasi'
                />
              </EuiFormRow>
              <EuiSpacer size='m' />
              {this.state.organizationId &&
                this.state.organizationId.length > 0 &&
                this.state.organizationName &&
                this.state.organizationName.length > 0 &&
                this.state.type !== 'HOTEL_STAFF' && (
                  <div
                    style={{
                      border: 1,
                      borderColor: 'black',
                      borderStyle: 'solid',
                      padding: 10,
                      borderRadius: 5,
                    }}
                  >
                    <EuiFormRow label='Klinik'>
                      <div>
                        {this.state.clinics &&
                          this.state.clinics.length < 1 && (
                            <span>Belum ada klinik yang dikaitkan.</span>
                          )}
                        {this.state.clinics &&
                          this.state.clinics.map((clinic, index) => {
                            return (
                              <EuiCard
                                title={clinic.name}
                                description={
                                  <EuiIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      let clinics = this.state.clinics;
                                      for (let i in clinics) {
                                        if (clinics[i].id === clinic.id) {
                                          clinics.splice(i, 1);
                                          break;
                                        }
                                      }
                                      this.setState({ clinics: clinics });
                                    }}
                                    type='trash'
                                  />
                                }
                                key={index}
                              />
                            );
                          })}
                      </div>
                    </EuiFormRow>
                    <div>
                      <EuiSpacer size='m' />
                      <EuiFormRow label='Organisasi/Group Layanan'>
                        <div>
                          <EuiSelect
                            placeholder='Organisasi/Group Layanan'
                            options={this.state.availableClinicOrganizations}
                            value={this.state.clinicOrganizationId}
                            name='clinicOrganizationId'
                            onChange={(e) => {
                              this.onClinicOrganizationChange(e);
                            }}
                            aria-label='Organisasi/Group Layanan'
                          />
                        </div>
                      </EuiFormRow>
                      {this.state.clinicOrganizationId &&
                        this.state.clinicOrganizationId.length > 0 &&
                        this.state.type !== 'HOTEL_STAFF' && (
                          <EuiFormRow label='Nama Klinik'>
                            <div>
                              <EuiSelect
                                placeholder='Klinik'
                                options={this.state.availableClinics}
                                value={this.state.pickedClinicId}
                                name='pickedClinicId'
                                onChange={(e) => {
                                  this.onPickedClinic(e);
                                }}
                                aria-label='Klinik'
                              />
                            </div>
                          </EuiFormRow>
                        )}
                      <EuiSpacer size='m' />
                      <EuiFlexGroup justifyContent={'flexStart'}>
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            disabled={!this.state.pickedClinicName}
                            onClick={() => {
                              this.addClinic(
                                this.state.pickedClinicId,
                                this.state.pickedClinicName
                              );
                            }}
                          >
                            Tambah
                          </EuiButton>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </div>
                  </div>
                )}
              <EuiSpacer size='m' />
              <EuiFormRow label='Foto profil'>
                <div>
                  {this.state.avatarLoading && (
                    <p>
                      <EuiLoadingSpinner size='xl' />
                    </p>
                  )}
                  {!this.state.avatarLoading && (
                    <p style={{ fontSize: 12, marginBottom: 4 }}>
                      Gunakan rasio gambar persegi atau ukuran 300x300 piksel.
                    </p>
                  )}
                  {!this.state.avatarLoading &&
                    this.state.avatar &&
                    this.state.avatar.length > 0 && (
                      <img
                        src={'data:image/jpeg;base64, ' + this.state.avatar}
                        width='330'
                        height='330'
                      />
                    )}
                  <EuiFilePicker
                    name='avatar'
                    id='iconFilePicker'
                    onChange={(files) => {
                      this.onAvatarChange(files);
                    }}
                  />
                </div>
              </EuiFormRow>
              {this.state.type === 'DOCTOR' && (
                <>
                  <EuiFormRow
                    label='Deskripsi'
                    isInvalid={this.state.validationFields['intro'].isInvalid}
                    error={
                      this.state.validationFields['intro'].isInvalid &&
                      this.state.validationFields['intro'].errors
                    }
                  >
                    <EuiTextArea
                      placeholder='Deskripsi'
                      value={this.state.intro}
                      name='intro'
                      onChange={this.handleChange}
                      aria-label='intro'
                    />
                  </EuiFormRow>
                  <EuiFormRow
                    label='Bahasa Dokter'
                    isInvalid={
                      this.state.validationFields['languageSupports'].isInvalid
                    }
                    error={
                      this.state.validationFields['languageSupports']
                        .isInvalid &&
                      this.state.validationFields['languageSupports'].errors
                    }
                  >
                    <EuiComboBox
                      placeholder='Bahasa Dokter'
                      options={ISO6391.getAllCodes().map((v) => {
                        return {
                          value: v,
                          label: `${ISO6391.getName(
                            v
                          )} (${ISO6391.getNativeName(v)})`,
                        };
                      })}
                      label={'Bahasa Dokter'}
                      isClearable={true}
                      selectedOptions={this.state.languageSupports || []}
                      name='languageSupports'
                      onChange={this.handleLanguageSupportsChange}
                      aria-label='Tag'
                    />
                  </EuiFormRow>
                  <EuiFormRow
                    label='Alumnus'
                    isInvalid={
                      this.state.validationFields['almameters'].isInvalid
                    }
                    error={
                      this.state.validationFields['almameters'].isInvalid &&
                      this.state.validationFields['almameters'].errors
                    }
                  >
                    <div
                      style={{
                        border: 1,
                        borderColor: 'black',
                        borderStyle: 'solid',
                        padding: 10,
                        borderRadius: 5,
                      }}
                    >
                      <div>
                        {(this.state.almameters || []).length < 1 && (
                          <span>Belum ada alumnus yang dikaitkan.</span>
                        )}
                        {(this.state.almameters || []).map((value, index) => {
                          return (
                            <EuiCard
                              style={{ marginTop: 5 }}
                              title={value.degree}
                              description={
                                <>
                                  <div>
                                    {value.almameter} ({value.year})
                                  </div>
                                  <EuiIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      let almameters = [
                                        ...this.state.almameters,
                                      ];
                                      almameters.splice(index, 1);
                                      this.setState({ almameters });
                                    }}
                                    type='trash'
                                  />
                                </>
                              }
                              key={index}
                            />
                          );
                        })}
                      </div>
                      <div>
                        <EuiHorizontalRule />
                        <EuiFormRow label='Alumnus'>
                          <EuiFieldText
                            placeholder='Alumnus'
                            value={this.state.almameter.almameter}
                            name='almameter'
                            onChange={(e) => {
                              let { value } = e.target;
                              let almameter = { ...this.state.almameter };
                              almameter.almameter = value;
                              this.setState({ almameter });
                            }}
                            aria-label='Alumnus'
                          />
                        </EuiFormRow>
                        <EuiFormRow label='Tahun'>
                          <EuiSelect
                            options={this.generateYears()}
                            placeholder='Tahun'
                            value={this.state.almameter.year}
                            name='year'
                            onChange={(e) => {
                              let { value } = e.target;
                              let almameter = { ...this.state.almameter };
                              almameter.year = Number(value);
                              this.setState({ almameter });
                            }}
                            aria-label='Tahun'
                          />
                        </EuiFormRow>
                        <EuiFormRow label='Gelar'>
                          <EuiFieldText
                            placeholder='Gelar'
                            value={this.state.almameter.degree}
                            name='degree'
                            onChange={(e) => {
                              let { value } = e.target;
                              let almameter = { ...this.state.almameter };
                              almameter.degree = value;
                              this.setState({ almameter });
                            }}
                            aria-label='Gelar'
                          />
                        </EuiFormRow>
                        <EuiSpacer size='m' />
                        <EuiFlexGroup justifyContent={'flexStart'}>
                          <EuiFlexItem grow={false}>
                            <EuiButton
                              disabled={
                                !this.state.almameter.almameter ||
                                !this.state.almameter.year ||
                                !this.state.almameter.degree
                              }
                              onClick={() =>
                                this.setState({
                                  almameter: {
                                    almameter: '',
                                    year: '',
                                    degree: '',
                                  },
                                  almameters: [
                                    ...this.state.almameters,
                                    this.state.almameter,
                                  ],
                                })
                              }
                            >
                              Tambah
                            </EuiButton>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </div>
                    </div>
                  </EuiFormRow>
                </>
              )}
              <EuiSpacer size='l' />
              <EuiFlexGroup justifyContent={'flexEnd'}>
                <EuiFlexItem grow={false}>
                  <EuiButton fill color='danger' onClick={this.delete}>
                    Hapus
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton fill onClick={this.save}>
                    Simpan
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiForm>
          </EuiPageContentBody>
        );
      case 'pertemuan':
        return (
          <EuiPageContentBody>
            {this.state.loading && (
              <p>
                <EuiLoadingSpinner size='xl' />
              </p>
            )}
            {!this.state.loading && (
              <EuiPage className='class'>
                <TablePage
                  data={this.state.sessionsData}
                  column={this.state.sessionsColumn}
                  location={this.props.location}
                  disablePagination={this.state.loading}
                  loading={this.state.loading}
                  page={this.state.page}
                  limit={this.state.limit}
                  total={this.state.total}
                  prev={this.prevPage}
                  next={this.nextPage}
                  toPage={this.page}
                />
              </EuiPage>
            )}
          </EuiPageContentBody>
        );
      case 'pendapatan':
        return (
          <EuiPageContentBody>
            <Table data={this.state.data} columns={this.state.column} />
          </EuiPageContentBody>
        );

      case 'lisensi':
        return (
          <EuiPageContentBody>
            <EuiForm className='form-layout' id='formLisensi'>
              <EuiFormRow label='Lisensi'>
                <EuiFieldText
                  disabled={true}
                  name='lisensi'
                  placeholder='Deskripsi'
                  value={this.state.licenseForm.lisensi}
                  onChange={(e) =>
                    this.licenseFormValueChanger(e.target.name, e.target.value)
                  }
                />
              </EuiFormRow>
              <EuiFormRow label='Nomor Lisensi'>
                <EuiFieldText
                  disabled={true}
                  name='nomorLisensi'
                  placeholder='Nomor Lisensi'
                  value={this.state.licenseForm.nomorLisensi}
                  onChange={(e) =>
                    this.licenseFormValueChanger(e.target.name, e.target.value)
                  }
                />
              </EuiFormRow>
              <EuiFormRow label='Masa Berlaku Hingga'>
                <EuiFieldText
                  disabled={true}
                  name='kadaluwarsa'
                  placeholder='Tanggal Kadaluwarsa'
                  value={this.state.licenseForm.kadaluwarsa}
                  onChange={(e) =>
                    this.licenseFormValueChanger(e.target.name, e.target.value)
                  }
                />
              </EuiFormRow>
              <EuiSpacer size='l' />
              <EuiFlexGroup justifyContent={'flexEnd'}>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    disabled={true}
                    fill
                    onClick={() => {
                      let tempData = this.state.licenseData;
                      const id = { id: this.state.licenseData.length };
                      const inputData = Object.assign(
                        {},
                        id,
                        this.state.licenseForm,
                        { action: 'Hapus' }
                      );
                      tempData.unshift(inputData);
                      this.setState({ licenseData: tempData });
                      this.licenseFormEmptier();
                    }}
                  >
                    Tambah
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiForm>
            <EuiSpacer />
            <Table
              data={this.state.licenseData}
              columns={this.state.licenseColumn}
            />
          </EuiPageContentBody>
        );
      default:
        break;
    }
  };

  prepareNewItem = () => {
    this.setState({
      isNewItem: true,
      type: '',
      id: '',
      avatarImage: '',
      clinicId: '',
      clinicName: '',
      emailAddress: '',
      name: '',
      organizationId: '',
      organizationName: '',
      phoneNumber: '',
      specialization: '',
      nip: '',
      nik: '',
      name_en: '',
      specializationEn: '',
      clinics: [],
    });
  };

  save = () => {
    let state = { ...this.state };
    this.setState({ saveLoading: true }, () => {
      Utils.validateFields(state).then((result) => {
        this.setState({ validationFields: result.validationFields });
        this.forceUpdate();

        // Normalize phone number
        var phoneNumber = Utils.reformatPhoneNumber(this.state.phoneNumber);
        var phoneNumbers = this.state.phoneNumbers;
        for (let i in phoneNumbers) {
          if (
            phoneNumbers[i].phone_number &&
            phoneNumbers[i].phone_number.length > 0
          ) {
            phoneNumbers[i].phone_number = Utils.reformatPhoneNumber(
              phoneNumbers[i].phone_number
            );
          }
        }
        let tags = [];
        for (let i in this.state.tags) {
          tags.push(this.state.tags[i].label);
        }

        if (this.state.id && this.state.id.length > 0) {
          if (!result.isValid) {
            console.log(result); // For debugging purpose
            return;
          }
          // Update existing
          let payload = {
            id: this.state.id,
            externalId: this.state.externalId,
            emailAddress: this.state.emailAddress,
            name: this.state.name,
            organizationId: this.state.organizationId,
            organizationName: this.state.organizationName,
            phoneNumber: phoneNumber,
            phoneNumbers: phoneNumbers,
            type: this.state.type,
            specialization: this.state.specialization,
            nip: this.state.nip,
            nik: this.state.nik,
            sip: this.state.sip,
            clinics: this.state.clinics,
            instant: this.state.instant,
            status: parseInt(this.state.status, 0),
            avatarImage: this.state.avatarImage,
            tags: tags,
            intro: this.state.intro,
            languageSupports: (this.state.languageSupports || []).map(
              (v) => v.value
            ),
            almameters: this.state.almameters,
            nameEn: this.state.nameEn,
            specializationEn: this.state.specializationEn,
          };
          staffService
            .update(payload)
            .then((result) => {
              this.setState({ saveLoading: false, loading: true });
              window.location.replace('/doctors');
            })
            .catch((err) => {
              console.log(err);
              this.setState({ saveLoading: false });
              if (
                err &&
                err.response.status === 409 &&
                err.response &&
                err.response.data &&
                err.response.data.message &&
                err.response.data.message.indexOf('phone-number') > -1
              ) {
                this.showToast(0);
              }
              errorService.handle(err);
            });
        } else {
          if (!result.isValid) {
            console.log(result); // For debugging purpose
            return;
          }
          // Create new one
          let payload = {
            emailAddress: this.state.emailAddress,
            name: this.state.name,
            organizationId: this.state.organizationId,
            organizationName: this.state.organizationName,
            phoneNumber: phoneNumber,
            phoneNumbers: phoneNumbers,
            type: this.state.type,
            specialization: this.state.specialization,
            nip: this.state.nip,
            nik: this.state.nik,
            sip: this.state.sip,
            clinics: this.state.clinics,
            instant: this.state.instant,
            avatarImage: this.state.avatarImage,
            tags: tags,
            intro: this.state.intro,
            languageSupports: (this.state.languageSupports || []).map(
              (v) => v.value
            ),
            almameters: this.state.almameters,
            nameEn: this.state.nameEn,
            specializationEn: this.state.specializationEn,
          };
          staffService
            .create(payload)
            .then((result) => {
              this.setState({ saveLoading: false, loading: true });
              window.history.back();
            })
            .catch((err) => {
              this.setState({ saveLoading: false });
              if (
                err &&
                err.response.status === 409 &&
                err.response &&
                err.response.data &&
                err.response.data.message &&
                err.response.data.message.indexOf('phone-number') > -1
              ) {
                this.showToast(0);
              }
              errorService.handle(err);
            });
        }
      });
    });
  };

  delete = () => {
    if (!(this.state.id && this.state.id.length > 0)) {
      return;
    }
    if (!window.confirm('Apakah Anda yakin ingin menghapus item ini?')) {
      return;
    }
    staffService
      .delete(this.state.id)
      .then((result) => {
        this.setState({ saveLoading: false, loading: true });
        window.history.back();
      })
      .catch((err) => {
        this.setState({ saveLoading: false });
        errorService.handle(err);
        window.history.back();
      });
  };

  showToast = (select) => {
    const toast = [
      {
        title: 'Error',
        color: 'danger',
        iconType: 'alert',
        text: (
          <div style={{ textAlign: 'left' }}>
            <p>Terdapat nomor telepon yang sama.</p>
            <p>
              Silakan cek kembali apakah data sudah benar dan periksa kembali
              jika ada nomor yang sama pada daftar staff.
            </p>
          </div>
        ),
      },
    ];

    let selectedToast = this.state.toasts;
    let toastId = this.state.toastId;
    selectedToast.push({ id: toastId++, ...toast[select] });

    this.setState({ toasts: selectedToast, toastId: toastId });
  };

  removeToast = (removedToast) => {
    console.log(removedToast.id);
    let toastsLeft = this.state.toasts.filter((toast) => {
      return toast.id !== removedToast.id;
    });

    console.log(toastsLeft);
    this.setState({ toasts: toastsLeft });
  };

  render() {
    return (
      <>
        {this.state.showModal && (
          <EuiOverlayMask onClick={this.closeModal}>
            <EuiModal onClose={this.closeModal}>
              <EuiModalHeader>
                <EuiModalHeaderTitle>Log Sesi</EuiModalHeaderTitle>
              </EuiModalHeader>
              <EuiModalBody>
                <EuiListGroup flush bordered={false} id='logs-list'>
                  {this.state.logsData.map((item, key) => {
                    let label = `${item.timestamp} - ${item.userType} ${item.type}`;
                    return (
                      <>
                        <EuiListGroupItem isActive key={key} label={label} />
                      </>
                    );
                  })}
                </EuiListGroup>
              </EuiModalBody>
              <EuiModalFooter>
                <EuiButton
                  onClick={() => {
                    this.printLogs(this.state.logsData);
                  }}
                  fill
                  color='secondary'
                >
                  Cetak
                </EuiButton>
                <EuiButton onClick={this.closeModal} fill>
                  Tutup
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
        <EuiPage className='euiNavDrawerPage'>
          <EuiPageBody className={'content-container'}>
            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                  <EuiTitle>
                    {this.state.isNewItem ? (
                      <h2>Staff baru</h2>
                    ) : (
                      <h2>
                        {this.state.type === 'DOCTOR'
                          ? 'Dokter'
                          : this.state.type === 'NURSE'
                          ? 'Asisten'
                          : this.state.type === 'PHARMACY'
                          ? 'Staff Farmasi'
                          : 'Staff Hotel'}
                      </h2>
                    )}
                  </EuiTitle>
                  {/*
                    Used to toggle assistant and doctor view. Disabled temporarily for future use.

                  <EuiSwitch
                    onChange={() => {
                      this.setState({ isDoctor: !this.state.isDoctor });
                    }}
                    checked={!this.state.isDoctor}
                  />
                  */}
                </EuiPageContentHeaderSection>
                <EuiPageContentHeaderSection>
                  <EuiFlexGroup>
                    {this.props.buttonLabel ? (
                      <EuiFlexItem grow={false}>
                        <EuiButton fill onClick={this.props.butonFunc}>
                          {this.props.buttonLabel}
                        </EuiButton>
                      </EuiFlexItem>
                    ) : null}
                  </EuiFlexGroup>
                </EuiPageContentHeaderSection>
              </EuiPageContentHeader>
              <EuiTabs>{this.renderTabs()}</EuiTabs>
              {this.renderTabsContent()}
            </EuiPageContent>
          </EuiPageBody>
          <EuiGlobalToastList
            toasts={this.state.toasts}
            dismissToast={this.removeToast}
            toastLifeTimeMs={5000}
          />
        </EuiPage>
      </>
    );
  }
}

export default StaffDetail;
