import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';
import Utils from '../Utils';

const ErrorService = new Error();
const MiscService = new Misc();

class SessionSlots {
  login = (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(Config.apiHost + '/api/login', payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getSessionSlots = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.Order || 'ASC';
      payload.search = payload.search || '';
      payload.searchBy = payload.searchBy || 'staffName';

      let url = `${Config.apiHost}/api/session-slots?page=${payload.page}&limit=${payload.limit}&order=${payload.order}`;
      if (payload.filterBy && payload.filterBy.length > 0) {
        url += `&filterBy=${payload.filterBy}`;
      }

      if (
        payload.search &&
        payload.search.length > 0 &&
        payload.searchBy &&
        payload.searchBy.length > 0
      ) {
        url += `&search=${payload.search}`;
        url += `&searchBy=${payload.searchBy}`;
      }
      //only check if scheduleType within payload is there, will return undefined if length is checked
      if (payload.scheduleType >= 0) {
        url += `&scheduleType=${payload.scheduleType}`;
      }
      if (payload.specificDate && payload.specificDate.length > 0) {
        url += `&specificDate=${payload.specificDate}`;
      }
      if (payload.organizationId && payload.organizationId.length > 0) {
        url += `&organizationId=${payload.organizationId}`;
      }
      if (payload.day && payload.day > -1) {
        url += `&day=${payload.day}`;
      }
      if (payload.staffId && payload.staffId.length > 0) {
        url += `&staffId=${payload.staffId}`;
      }
      if (payload.clinicId && payload.clinicId.length > 0) {
        url += `&clinicId=${payload.clinicId}`;
      }
      console.log(url)

      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          let data = Utils.camelCase(response.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  create = (payload) => {
    console.log(payload);
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload = Utils.snakeCase(payload);
      let url = `${Config.apiHost}/api/session-slots`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  update = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload = Utils.snakeCase(payload);
      let url = `${Config.apiHost}/api/session-slot/${payload.id}`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  delete = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session-slot/${id}`;
      axios
        .delete(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  import = (payload) => {
    const { organizationId, clinicId, file } = payload;
    const url = `${Config.apiHost}/api/session-slots/import?organization_id=${organizationId}&clinic_id=${clinicId}`;
    const formData = new FormData();
    formData.append('file', file[0]);
    return new Promise((resolve, reject) => {
      axios
        .post(url, formData, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  updateMaxCount = (slotId, maxCount) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session-slot/constraints/max-count/${slotId}`;
      let payload = {
        slot_id: `${slotId}`,
        max_count: parseInt(maxCount),
      };
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getMaxCount = (slotId) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session-slot/constraints/max-count/${slotId}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
}

export default SessionSlots;
