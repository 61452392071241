import React, { useEffect, useState } from 'react';
import { EuiBadge, EuiLoadingSpinner, EuiToolTip } from '@elastic/eui';
import EventsService from '../../services/Events';
import CustomBadge from '../../components/Badge';

const eService = new EventsService();

const EventState = {
  Unknown: 0,
  Error: 1,
  Success: 2,
  DataCleared: 3,
  ChannelCancelUp: 4,
};

const selectedSession = (id, list) => {
  let session = list.find((s) => s.id === id);
  return session;
};

const statusRender = (status) => {
  switch (status) {
    case 0:
      return 'NEW';
    case 1:
      return 'ACTIVE';
    case 2:
      return 'REJECTED';
    case 3:
      return 'DISABLED';
    case 4:
      return 'ENDED';
    case 5:
      return 'CANCELED';
    case 7:
      return 'REFUND';
    default:
      return 'UNKNOWN';
  }
};

const statusColor = (status) => {
  switch (status) {
    case 0:
      return '#ffc03f';
    case 1:
      return '#C8EEBE';
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
      return '#FFCAC8';
    default:
      return '#D9D9D9';
  }
};

const indicatorColor = (status) => {
  switch (status) {
    case EventState.Unknown:
      return '#000000';
    case EventState.Error:
      return '#FF2424';
    case EventState.DataCleared:
    case EventState.ChannelCancelUp:
      return '#FFB524';
    case EventState.Success:
      return '#21B214';
    default:
      return '#000000';
  }
};
const eventStatus = (status) => {
  switch (status) {
    case EventState.Unknown:
      return 'Unknown';
    case EventState.Error:
      return 'Error';
    case EventState.Success:
      return 'Success';
    case EventState.DataCleared:
      return 'Data Cleared';
    case EventState.ChannelCancelUp:
      return 'Channel Cancel Up';
    default:
      return 'Unknown';
  }
};

const eventsBySession = async (sessionId) => {
  let payload = {
    secondary_key: sessionId,
    code: 'SESSION_PAYMENT_RECEIVED',
    limit: 1,
    order: 'DESC',
    orderBy: 'timestamp',
  };
  try {
    let _events = await eService.list(payload);
    if (
      _events !== null &&
      _events.list.length > 0 &&
      _events.list[0].result_state >= 0
    ) {
      return _events.list[0].result_state;
    }
  } catch (e) {
    console.log(e);
  }
  return EventState.Unknown;
};

const SessionBadge = (props) => {
  const { sessionId, sessionList } = props;
  const [Color, setColor] = useState(null);
  const [Status, setStatus] = useState(null);
  const [EventStatus, setEventStatus] = useState(null);
  const [IconColor, setStatusColor] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const session = selectedSession(sessionId, sessionList);
      const status = statusRender(session.status);
      const color = statusColor(session.status);
      //   show third party status indicator only on ended session
      if (session.status >= 1) {
        const eventState = await eventsBySession(sessionId);
        const iconColor = indicatorColor(eventState);
        setEventStatus(eventStatus(eventState));
        setStatusColor(iconColor);
      }
      setStatus(status);
      setColor(color);
    };
    getData();
  }, [sessionId, sessionList]);

  if (Color !== null) {
    const tooltip = EventStatus
      ? 'SESSION_PAYMENT_RECEIVED: ' + EventStatus
      : Status;
    return (
      <EuiToolTip position='top' content={tooltip}>
        <CustomBadge color={Color} text={Status} iconColor={IconColor} />
      </EuiToolTip>
    );
  } else {
    return (
      <EuiBadge
        color={'#fefefe'}
        style={{ color: '#000000', borderRadius: '10px' }}
      >
        <EuiLoadingSpinner size='l' />
      </EuiBadge>
    );
  }
};

export default SessionBadge;
