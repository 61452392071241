import React, { Component } from 'react';
import {
  EuiPage,
  EuiPageBody,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiFieldText,
  EuiModalFooter,
  EuiButton,
  EuiFlexItem,
  EuiFlexGroup,
  EuiCodeBlock,
  EuiDatePicker,
  EuiSelect,
  EuiButtonEmpty,
  EuiDatePickerRange,
} from '@elastic/eui';
import moment from 'moment';
import TablePage from '../components/TablePage';
import JSONPretty from 'react-json-pretty';
import ThirdpartyLogService from '../services/ThirdpartyLogs';

const thirdPartyLogs = new ThirdpartyLogService();

class ThirdpartyLogs extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    order: 'desc',
    orderBy: 'timestamp',
    loading: false,
    saveLoading: false,
    deleteLoading: false,
    startDate: moment(),
    endDate: moment(),
    modalSearchDate: false,
    useSearchDate: false,
    data: [],
    column: [
      {
        field: 'timestamp',
        name: 'Tanggal',
        sortable: true,
        render: (timestamp) =>
          moment.utc(timestamp).local().format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        field: 'thirdparty_name',
        name: 'Nama Pihak Ketiga',
      },
      {
        field: 'method',
        name: 'Method',
      },
      {
        field: 'endpoint',
        name: 'Endpoint',
      },
      {
        field: 'payload',
        name: 'Payload',
        truncateText: true,
      },
      {
        field: 'status_code',
        name: 'Status',
      },
    ],
    selectedItem: {
      id: '',
      thirdparty_name: '',
      method: '',
      endpoint: '',
      payload: {
        body: '',
        header: '',
      },
      response: {
        body: '',
        header: '',
      },
      status_code: 0,
      timestamp: '',
      curl: '',
    },
    keySearches: [
      {
        value: 'thirdparty_name',
        text: 'Nama Pihak Ketiga',
      },
      {
        value: 'method',
        text: 'Method',
      },
      {
        value: 'endpoint',
        text: 'Endpoint',
      },
      {
        value: 'payload',
        text: 'Payload',
      },
      {
        value: 'status_code',
        text: 'Status',
      },
    ],
    keySearch: (this.props.location.state && this.props.location.state.key) || 'thirdparty_name',
    search: (this.props.location.state && this.props.location.state.keyword) || '',
  };

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true }, () => {
      let payload = {
        page: page,
        limit: this.state.limit,
        key: this.state.keySearch,
        search: this.state.search,
        order: this.state.order,
        orderBy: this.state.orderBy,
        startDateUnix: this.state.startDate.startOf('day').unix(),
        endDateUnix: this.state.endDate.endOf('day').unix(),
      };

      var result;
      thirdPartyLogs
        .getThirdpartyLogs(payload)
        .then((response) => {
          result = response;
          this.setState(
            {
              page: result.page,
              limit: result.limit,
              total: result.total,
              data: result.logs || [],
              loading: false,
              modalSearchDate: false,
            },
            () => {
              this.forceUpdate();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            loading: false,
            data: [],
            modalSearchDate: false,
          });
        });
    });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  componentDidMount = () => {
    this.page(1);
  };

  onItemClick = (item) => {
    console.log(item);
    thirdPartyLogs
      .getThirdpartyLogsById(item.id)
      .then((res) => {
        let payloadBody = {};
        let payloadHeader = {};
        let responseBody = {};
        let responseHeader = {};

        try {
          payloadBody = JSON.parse(res.payload).body;
        } catch (e) {
          console.log(e);
        }

        try {
          payloadHeader = JSON.parse(res.payload).header;
        } catch (e) {
          console.log(e);
        }

        try {
          responseBody = JSON.parse(res.response).body;
        } catch (e) {
          console.log(e);
        }

        try {
          responseHeader = JSON.parse(res.response).header;
        } catch (e) {
          console.log(e);
        }

        this.setState({
          modal: true,
          selectedItem: {
            id: res.id,
            thirdparty_name: res.thirdparty_name,
            method: res.method,
            endpoint: res.endpoint,
            payload: {
              body: payloadBody,
              header: payloadHeader,
            },
            response: {
              body: responseBody,
              header: responseHeader,
            },
            status_code: res.status_code || '-',
            timestamp: res.timestamp,
            curl: res.curl || '',
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleOnSearch = () => {
    this.setState(
      {
        loading: true,
      },
      () => this.page(1)
    );
  };

  render() {
    const sorting = {
      sort: {
        field: this.state.orderBy,
        direction: this.state.order,
      },
    };

    return (
      <>
        {this.state.modal && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => {
                this.setState({ modal: false });
              }}
              initialFocus='[name=popswitch]'
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  Detail Riwayat Pihak Ketiga
                </EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiFlexGroup>
                  <EuiFlexItem>Tanggal</EuiFlexItem>
                  <EuiFlexItem>{this.state.selectedItem.timestamp}</EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>Sumber</EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.thirdparty_name}
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>Method</EuiFlexItem>
                  <EuiFlexItem>{this.state.selectedItem.method}</EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>Endpoint</EuiFlexItem>
                  <EuiFlexItem>{this.state.selectedItem.endpoint}</EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>Status</EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.status_code}
                  </EuiFlexItem>
                </EuiFlexGroup>
                {this.state.selectedItem.payload.header !== '' ? (
                  <>
                    <EuiFlexGroup>
                      <EuiFlexItem>Payload Header</EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiCodeBlock language='json' isCopyable>
                          <JSONPretty
                            data={this.state.selectedItem.payload.header}
                          ></JSONPretty>
                        </EuiCodeBlock>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </>
                ) : null}
                {this.state.selectedItem.payload.body !== '' ? (
                  <>
                    <EuiFlexGroup>
                      <EuiFlexItem>Payload Body</EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiCodeBlock language='json' isCopyable>
                          <JSONPretty
                            data={this.state.selectedItem.payload.body}
                          ></JSONPretty>
                        </EuiCodeBlock>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </>
                ) : null}
                {this.state.selectedItem.response.header !== '' ? (
                  <>
                    <EuiFlexGroup>
                      <EuiFlexItem>Response Header</EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiCodeBlock language='json' isCopyable>
                          <JSONPretty
                            data={this.state.selectedItem.response.header}
                          ></JSONPretty>
                        </EuiCodeBlock>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </>
                ) : null}
                {this.state.selectedItem.response.body !== '' ? (
                  <>
                    <EuiFlexGroup>
                      <EuiFlexItem>Response Body</EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiCodeBlock language='json' isCopyable>
                          <JSONPretty
                            data={this.state.selectedItem.response.body}
                          ></JSONPretty>
                        </EuiCodeBlock>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </>
                ) : null}
                <EuiFlexGroup>
                  <EuiFlexItem>Curl</EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexItem>
                  <EuiFlexItem>
                    <EuiCodeBlock language='text' isCopyable>
                      {`${this.state.selectedItem.curl}`}
                    </EuiCodeBlock>
                  </EuiFlexItem>
                </EuiFlexItem>
              </EuiModalBody>
              <EuiModalFooter>
                <EuiButton
                  color={'primary'}
                  onClick={() =>
                    this.setState({
                      modal: false,
                    })
                  }
                  fill
                >
                  Tutup
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
        <EuiPage className='class'>
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
            <TablePage
              title={'Riwayat Pihak Ketiga'}
              placeholder={'Daftar riwayat'}
              data={this.state.data}
              column={this.state.column}
              disablePagination={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
              loading={this.state.loading}
              sort={sorting}
              change={(v) => {
                this.setState(
                  {
                    order: v.sort.direction,
                    loading: true,
                  },
                  () => this.page(this.state.page)
                );
              }}
              searchComp={
                <>
                  <EuiFlexGroup style={{ marginRight: 5 }}>
                    <EuiFlexItem>
                      <EuiSelect
                        options={this.state.keySearches}
                        value={this.state.keySearch}
                        name='key'
                        onChange={(e) =>
                          this.setState({ keySearch: e.target.value })
                        }
                        aria-label='Key'
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFieldText
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            this.handleOnSearch();
                          }
                        }}
                        value={this.state.search}
                        onChange={(e) =>
                          this.setState({ search: e.target.value })
                        }
                        placeholder={'Search...'}
                        append={
                          <EuiButtonEmpty
                            onClick={() =>
                              this.handleOnSearch(this.state.search)
                            }
                          >
                            Cari
                          </EuiButtonEmpty>
                        }
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiFlexGroup>
                    <EuiFlexItem grow={false}>
                      <EuiDatePickerRange
                        startDateControl={
                          <EuiDatePicker
                            dateFormat={'YYYY-MM-DD'}
                            maxDate={moment()}
                            selected={this.state.startDate}
                            onChange={(
                              date // in moment object format
                            ) =>
                              this.setState({
                                startDate: date,
                                endDate: this.state.endDate.isBefore(date)
                                  ? date
                                  : this.state.endDate,
                              })
                            }
                          />
                        }
                        endDateControl={
                          <EuiDatePicker
                            dateFormat={'YYYY-MM-DD'}
                            minDate={this.state.startDate}
                            maxDate={moment()}
                            onChange={(
                              date // in moment object format
                            ) =>
                              this.setState({
                                endDate: date,
                              })
                            }
                            selected={this.state.endDate}
                          />
                        }
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton
                        onClick={() =>
                          this.handleOnSearch(this.state.search, true)
                        }
                        fill
                      >
                        Cari
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton
                        onClick={() => {
                          this.setState(
                            {
                              startDate: moment(),
                              endDate: moment(),
                              search: '',
                              order: 'desc',
                            },
                            () => this.handleOnSearch(this.state.search)
                          );
                        }}
                      >
                        Reset pencarian
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </>
              }
            />
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default ThirdpartyLogs;