import React, {  useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { EuiNavDrawer, EuiSideNav, EuiHorizontalRule } from '@elastic/eui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBinoculars,
  faWallet,
  faUserInjured,
  faUserMd,
  faUserNurse,
  faShieldAlt,
  faClinicMedical,
  faHospital,
  faCalendarDay,
  faCalendarAlt,
  faQrcode,
  faDatabase,
  faUser,
  faTablets,
  faMortarPestle,
  faCogs,
  faCalendarCheck,
  faMobile,
  faPills,
  faSync,
  faGlobeAsia,
  faDesktop,
  faBug,
  faMoneyBillWaveAlt,
  faCalendar,
  faBook,
  faMicroscope,
  faFlag,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import Config from './Config';

const Sidebar = () => {
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const navigateHistory = useHistory();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const sidenav = [
    {
      id: 0,
      name: 'Telekonsul',
      icon: <FontAwesomeIcon icon={faUserInjured} />,
      forceOpen: false,
      isSelected: false,
      items: [
        /* Commented for template
          {
            id: '',
            name: '',
            isSelected: selected === '',
            icon: <FontAwesomeIcon icon={faUserInjured} />,
            onClick: () => {
              setSelected('')
            },
            items: [
              {
                id: '',
                name: '',
                isSelected: selected === '',
                icon: <FontAwesomeIcon icon={faUserInjured} />,
                onClick: () => {
                  setSelected('')
                },
              },
            ],
          },
          */
        {
          id: 'landing',
          name: 'Dashboard',
          isSelected: selected === 'landing',
          icon: <FontAwesomeIcon icon={faHome} />,
          onClick: () => {
            setSelected('landing');
            navigateHistory.push('/');
          },
        },
        {
          id: 'sessions',
          name: 'Sesi',
          isSelected: selected === 'sessions',
          icon: <FontAwesomeIcon icon={faCalendarDay} />,
          onClick: () => {
            setSelected('sessions');
          },
          items: [
            {
              id: 'todaySessions',
              name: 'Jadwal Dokter Hari ini',
              isSelected: selected === '',
              icon: <FontAwesomeIcon icon={faCalendarDay} />,
              onClick: () => {
                setSelected('todaySessions');
                navigateHistory.push('/sessions');
              },
            },
            {
              id: 'sessionSlots',
              name: 'Pengaturan Jadwal',
              isSelected: selected === 'sessionSlots',
              icon: <FontAwesomeIcon icon={faCalendarAlt} />,
              onClick: () => {
                setSelected('sessionSlots');
                navigateHistory.push('/session-slots');
              },
            },
            /*
              // TODO The URL is still pointed to patients page
              {
                id: 'orders',
                name: 'Pemesanan',
                isSelected: selected === 'orders',
                icon: <FontAwesomeIcon icon={faWallet} />,
                onClick: () => {
                  setSelected('orders')
                  navigateHistory.push('/orders');
                },
              },
              */
            {
              id: 'consultation',
              name: 'Temu-Janji Konsultasi',
              isSelected: selected === 'consultation',
              icon: <FontAwesomeIcon icon={faCalendarCheck} />,
              onClick: () => {
                setSelected('consultation');
                navigateHistory.push('/consultations');
              },
            },
            {
              id: 'payments',
              name: 'Pembayaran',
              isSelected: selected === 'payments',
              icon: <FontAwesomeIcon icon={faWallet} />,
              onClick: () => {
                setSelected('payments');
                navigateHistory.push('/payments');
              },
            },
          ],
        },
        {
          id: 'staffs',
          name: 'Staff',
          isSelected: selected === 'staffs',
          icon: <FontAwesomeIcon icon={faUser} />,
          onClick: () => {
            setSelected('staffs');
          },
          items: [
            {
              id: 'doctor',
              name: 'Dokter',
              isSelected: selected === 'doctor',
              icon: <FontAwesomeIcon icon={faUserMd} />,
              onClick: () => {
                setSelected('doctor');
                navigateHistory.push('/doctors');
              },
            },
            {
              id: 'assitants',
              name: 'Asisten/Perawat',
              isSelected: selected === 'assitants',
              icon: <FontAwesomeIcon icon={faUserNurse} />,
              onClick: () => {
                setSelected('assistants');
                navigateHistory.push('/assistants');
              },
            },
            {
              id: 'pharmacyStaffs',
              name: 'Staff Farmasi',
              isSelected: selected === 'pharmacyStaffs',
              icon: <FontAwesomeIcon icon={faPills} />,
              onClick: () => {
                setSelected('pharmacyStaffs');
                navigateHistory.push('/staffs-pharmacy');
              },
            },
          ],
        },
        {
          id: 'patients',
          name: 'Pasien',
          isSelected: selected === 'patients',
          icon: <FontAwesomeIcon icon={faUserInjured} />,
          onClick: () => {
            setSelected('patients');
          },
          items: [
            {
              id: 'internalPatients',
              name: 'Pasien',
              isSelected: selected === 'internalPatients',
              icon: <FontAwesomeIcon icon={faUserInjured} />,
              onClick: () => {
                setSelected('internalPatients');
                navigateHistory.push('/patients');
              },
            },
            {
              id: 'externalPatients',
              name: 'Pasien Eksternal',
              isSelected: selected === 'externalPatients',
              icon: <FontAwesomeIcon icon={faUserInjured} />,
              onClick: () => {
                setSelected('externalPatients');
                navigateHistory.push('/patients-external');
              },
            },
          ],
        },
        {
          id: 'medicines',
          name: 'Obat',
          icon: <FontAwesomeIcon icon={faUserInjured} />,
          isSelected: selected === 'medicines',
          onClick: () => {
            setSelected('medicines');
          },
          items: [
            {
              id: 'pharmacy-groups',
              name: 'Grup Apotek',
              icon: <FontAwesomeIcon icon={faClinicMedical} />,
              isSelected: selected === 'pharmacyGroups',
              onClick: () => {
                setSelected('pharmacyGroups');
                navigateHistory.push('/pharmacy-groups');
              },
            },
            {
              id: 'dosage',
              name: 'Dosis',
              icon: <FontAwesomeIcon icon={faUserInjured} />,
              isSelected: selected === 'dosage',
              onClick: () => {
                setSelected('dosage');
                navigateHistory.push('/medicine-dosages');
              },
            },
            {
              id: 'application',
              name: 'Penggunaan',
              icon: <FontAwesomeIcon icon={faUserInjured} />,
              isSelected: selected === 'application',
              onClick: () => {
                setSelected('application');
                navigateHistory.push('/medicine-applications');
              },
            },
            {
              id: 'medicine',
              name: 'Obat',
              icon: <FontAwesomeIcon icon={faTablets} />,
              isSelected: selected === 'medicine',
              onClick: () => {
                setSelected('medicine');
                navigateHistory.push('/medicines');
              },
            },
          ],
        },
        {
          id: 'org',
          name: 'Organisasi',
          isSelected: selected === 'org',
          icon: <FontAwesomeIcon icon={faHospital} />,
          onClick: () => {
            setSelected('org');
          },
          items: [
            {
              id: 'organizations',
              name: 'Organisasi dan Unit',
              isSelected: selected === 'organizations',
              icon: <FontAwesomeIcon icon={faHospital} />,
              onClick: () => {
                setSelected('organizations');
                navigateHistory.push('/organizations');
              },
            },
            {
              id: 'clinics',
              name: 'Klinik',
              isSelected: selected === 'clinics',
              icon: <FontAwesomeIcon icon={faClinicMedical} />,
              onClick: () => {
                setSelected('clinics');
                navigateHistory.push('/clinics');
              },
            },
            {
              id: 'products',
              name: 'Produk',
              isSelected: selected === 'products',
              icon: <FontAwesomeIcon icon={faDatabase} />,
              onClick: () => {
                setSelected('products');
                navigateHistory.push('/products');
              },
            },
          ],
        },
        {
          id: 'adminPharmacies',
          name: 'Apotek',
          isSelected: selected === 'adminPharmacies',
          icon: <FontAwesomeIcon icon={faMortarPestle} />,
          onClick: () => {
            setSelected('adminPharmacies');
          },
          items: [
            {
              id: 'pharmaciesKimiaFarma',
              name: 'Kimia Farma',
              isSelected: selected === 'pharmaciesKimiaFarma',
              icon: <FontAwesomeIcon icon={faMortarPestle} />,
              onClick: () => {
                setSelected('pharmaciesKimiaFarma');
                navigateHistory.push('/pharmacies/kimia-farma');
              },
            },
          ],
        },
        {
          id: 'adminLaboratoryTest',
          name: 'Uji Lab',
          isSelected: selected === 'adminLaboratoryTest',
          icon: <FontAwesomeIcon icon={faMicroscope} />,
          onClick: () => {
            setSelected('adminLaboratoryTest');
            navigateHistory.push('/laboratory-test');
          },
        },
        {
          id: 'adminUser',
          name: 'Administrator',
          isSelected: selected === 'adminUser',
          icon: <FontAwesomeIcon icon={faShieldAlt} />,
          onClick: () => {
            setSelected('adminUser');
          },
          items: [
            {
              id: 'adminUsers',
              name: 'Pengguna Admin',
              isSelected: selected === 'adminUsers',
              icon: <FontAwesomeIcon icon={faShieldAlt} />,
              onClick: () => {
                setSelected('adminUsers');
                navigateHistory.push('/administrators');
              },
            },
            {
              id: 'settings',
              name: 'Pengaturan',
              isSelected: selected === 'settings',
              icon: <FontAwesomeIcon icon={faCogs} />,
              onClick: () => {
                setSelected('settings');
                navigateHistory.push('/settings');
              },
            },
            {
              id: 'app-settings',
              name: 'Pengaturan Aplikasi',
              isSelected: selected === 'app-settings',
              icon: <FontAwesomeIcon icon={faMobile} />,
              onClick: () => {
                setSelected('app-settings');
                navigateHistory.push('/app-settings');
              },
            },
            {
              id: 'sync-bridge',
              name: 'Pengaturan Sinkronisasi',
              isSelected: selected === 'sync-bridge',
              icon: <FontAwesomeIcon icon={faSync} />,
              onClick: () => {
                setSelected('sync-bridge');
                navigateHistory.push('/sync-bridge');
              },
            },
            {
              id: 'region-codes',
              name: 'Kode Wilayah',
              isSelected: selected === 'region-codes',
              icon: <FontAwesomeIcon icon={faGlobeAsia} />,
              onClick: () => {
                setSelected('region-codes');
                navigateHistory.push('/region-codes');
              },
            },
            {
              id: 'thirdparty-logs',
              name: 'Bridge log browser',
              isSelected: selected === 'thirdparty-logs',
              icon: <FontAwesomeIcon icon={faDesktop} />,
              onClick: () => {
                setSelected('thirdparty-logs');
                navigateHistory.push('/thirdparty-logs');
              },
            },
            {
              id: 'check-event-error',
              name: 'Periksa Error',
              isSelected: selected === 'check-event-error',
              icon: <FontAwesomeIcon icon={faBug} />,
              onClick: () => {
                setSelected('check-event-error');
                navigateHistory.push('/check-event-error');
              },
            },
            {
              id: 'voucher-useds',
              name: 'Penggunaan Voucher',
              isSelected: selected === 'voucher-useds',
              icon: <FontAwesomeIcon icon={faMoneyBillWaveAlt} />,
              onClick: () => {
                setSelected('voucher-useds');
                navigateHistory.push('/voucher-useds');
              },
            },
            {
              id: 'vouchers',
              name: 'Voucher',
              isSelected: selected === 'vouchers',
              icon: <FontAwesomeIcon icon={faMoneyBillWaveAlt} />,
              onClick: () => {
                setSelected('vouchers');
                navigateHistory.push('/vouchers');
              },
            },
            {
              id: 'events',
              name: 'Events',
              isSelected: selected === 'events',
              icon: <FontAwesomeIcon icon={faCalendar} />,
              onClick: () => {
                setSelected('events');
                navigateHistory.push('/events');
              },
            },
            {
              id: 'banner',
              name: 'Banner',
              isSelected: selected === 'banner',
              icon: <FontAwesomeIcon icon={faFlag} />,
              onClick: () => {
                setSelected('banner');
                navigateHistory.push('/banner');
              },
            },
          ],
        },
        {
          id: 'whatsapp',
          name: 'Whatsapp',
          isSelected: selected === 'whatsapp',
          icon: <FontAwesomeIcon icon={faQrcode} />,
          onClick: () => {
            setSelected('whatsapp');
            navigateHistory.push('/whatsapp');
          },
        },
        {
          id: 'monitoring',
          name: 'Pemantauan',
          isSelected: selected === 'monitoring',
          icon: <FontAwesomeIcon icon={faBinoculars} />,
          onClick: () => {
            setSelected('monitoring');
            navigateHistory.push('/monitoring');
          },
        },
      ],
    },
  ];

  let supersetNav = (Config.supersetPages || []).map((v) => {
    return {
      id: v.id,
      name: v.name,
      icon: <FontAwesomeIcon icon={faDatabase} />,
      isSelected: selected === v.id,
      onClick: () => {
        setSelected(v.id);
        navigateHistory.push('/' + v.id);
      },
    };
  });

  let finalSideNav = [...sidenav];

  if (supersetNav.length > 0) {
    let parent = {
      id: 'report',
      name: 'Laporan',
      isSelected: selected === 'report',
      icon: <FontAwesomeIcon icon={faBook} />,
      onClick: () => {
        setSelected('report');
      },
      items: [...supersetNav],
    };

    finalSideNav[0].items = [...sidenav[0].items, parent];
  }

  return (
    <EuiNavDrawer
      onMouseEnter={() => {
        if (
          document
            .getElementsByClassName('euiNavDrawer')[0]
            .className.includes('euiNavDrawer-isCollapsed')
        ) {
          setNavIsOpen(true);
          console.log('mouseEnter: ', navIsOpen);
          document
            .getElementsByClassName('euiListGroupItem__button')[0]
            .click();
        }
      }}
      onMouseLeave={() => {
        if (
          !document
            .getElementsByClassName('euiNavDrawer')[0]
            .className.includes('euiNavDrawer-isCollapsed')
        ) {
          setNavIsOpen(false);
          console.log('mouseleave: ', navIsOpen);
          document
            .getElementsByClassName('euiListGroupItem__button')[0]
            .click();
        }
      }}
    >
      <EuiHorizontalRule margin='none' />
      <div>
        {(navIsOpen || isMobile ) && (
          <EuiSideNav
            id='sidenav'
            heading={false}
            items={finalSideNav}
            isOpenOnMobile={true}
          />
        )}
        {!navIsOpen && !isMobile && (
          <div
            style={{
              transform: 'rotate(90deg)',
              marginTop: '40vh',
              textAlign: 'center',
            }}
          >
            <p>Menu</p>
          </div>
        )}
      </div>
    </EuiNavDrawer>
  );
};

export default Sidebar;
