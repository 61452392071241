import React, { Component } from "react";
import { EuiPage, EuiPageBody } from "@elastic/eui";
import { debounce } from 'lodash';
import TablePage from "../components/TablePage";
import ToExcels from "../services/ToExcels";
import Blob from "../services/Blob";
import downloadjs from "downloadjs";
import moment from "moment";
import Payments from "../services/Payments";

const toExcel = new ToExcels();
const blob = new Blob();
const payments = new Payments();

class PaymentRecaps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffType: this.identifyStaffType(),
      page: 1,
      limit: 10,
      order: "ASC",
      search: "",
      loading: true,
      orderBy: "name",
      data: [],
      column: [
        {
          field: "schedule_string",
          name: "Jadwal",
          width: '7%',
        },
        {
          field: "session_status",
          name: "Status",
          width: '7%',
        },
        {
          field: "full_name",
          name: "Pasien",
          width: '10%',
        },
        {
          field: "staff_name",
          name: "Dokter",
          width: '20%',
        },
        {
          field: "organization_name",
          name: "Organisasi",
          width: '10%',
        },
        {
          field: "clinic_name",
          name: "Klinik",
        },
        {
          field: "staff_type",
          name: "Type",
          width: '7%',
        },
        {
          field: "fee",
          name: "Fee",
          width: '7%',
        }
      ]
    };
  }

  componentDidMount = () => {
    const search = this.props.location.search;
    const searchIndex = search.search("q=");
    if (searchIndex !== -1) {
      const searchQuery = search.slice(searchIndex + 2);
      this.handleOnSearch(searchQuery);
    }
    this.page(1);
  };

  page = page => {
    page = page || 1;
    this.setState({ loading: true });
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };
    payments
      .getPaymentRecaps(payload)
      .then(result => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          order: result.order,
          orderBy: result.orderBy,
          data: result.items,
          loading: false
        }, () => {
          this.forceUpdate();
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
          data: []
        });
      });
  };

  handleOnSearch = (value) => {
    this.setState({ search: value, loading: true });
    this.handleSearchDebounce();
  }

  handleSearchDebounce = debounce(() => {
    const payload = {
      search: this.state.search,
      type: ""
    }
    payments
      .getPaymentRecaps(payload)
      .then(result => {
        console.log(result)
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          order: result.order,
          orderBy: result.orderBy,
          data: result.items,
          loading: false
        },() => {
          this.forceUpdate();
        });
      })
      .catch(err => {
        console.log(err)
      })
  }, 2000)

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  onItemClick = item => {
    this.props.history.push("/staff/detail/" + item.id);
  };

  identifyStaffType = () => {
    let staffType = this.props.match.path.split("/")
    return staffType[1]
  }

  toExcel = () => {
    let payload = {
      page: 1,
      limit: this.state.total,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy
    };
    toExcel
      .paymentRecap(payload)
      .then((result) => {
        blob
          .getBlob(result.id)
          .then((res) => {
            downloadjs(
              `data:${res.contentType};base64,${res.base64}`,
              `Rekap_Pembayaran_${moment().format("YYYYMMDDHHmmss")}.xlsx`,
              `${res.contentType}`
            );
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: "row" }}
            className={"content-container"}
          >
            <TablePage
            title="Rekap Pembayaran"
            placeholder="Rekap Pembayaran"
              buttonDownloadExcelLabel={"Download Excel"}
              buttonDownloadExcelFunc={() => {
                this.toExcel();
              }}
              data={this.state.data}
              column={this.state.column}
              location={this.props.location}
              disablePagination={this.state.loading}
              loading={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
            />
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default PaymentRecaps;
