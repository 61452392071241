import React, { Component } from 'react';
import { debounce } from 'lodash';
import {
  EuiPage,
  EuiPageBody,
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiOverlayMask,
  EuiButtonEmpty,
  EuiConfirmModal,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiSelect,
  EuiFilePicker,
  EuiSwitch,
  EuiFlexItem,
  EuiFieldNumber,
  EuiLoadingSpinner,
  EuiFlexGroup,
  EuiButtonToggle,
  EuiImage,
  EuiBadge,
  EuiComboBox,
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import OrganizationService from '../services/Organizations';
import ClinicService from '../services/Clinics';
import ErrorService from '../services/Error.js';
import Utils from '../Utils';
import swal from 'sweetalert';
import ExtraDataService from '../services/extraData';
import { Link } from 'react-router-dom';
import ProductService from '../services/Products';

const organizationService = new OrganizationService();
const clinicService = new ClinicService();
const errorService = new ErrorService();
const extraDataService = new ExtraDataService();
const productService = new ProductService();

class Clinic extends Component {
  state = {
    page: 1,
    limit: 10,
    order: 'ASC',
    search: '',
    loading: false,
    extraDataLoading: false,
    orderBy: 'name',
    prioritize: false,
    onlyExistingPatients: false,
    addOnRegistration: false,
    mandatory: false,
    saveLoading: false,
    productLoading: false,
    deleteProductLoading: false,
    modalAddProduct: false,
    data: [],
    extraData: [],
    homeCareClinicVariantName: '',
    homeCareClinicVariantId: '',
    clinicTypeId: '',
    clinicTypeName: '',
    addproductPage: 1,
    addproductLimit: 10,
    addproductOrder: 'ASC',
    addproductTotal: 0,
    addProductData: [],
    addProductLoading: false,
    externalId: '',
    homeCareClinicVariant: [
      {
        value: 0,
        text: 'Pilih variant',
      },
      {
        value: 1,
        text: 'REMOTE',
      },
      {
        value: 2,
        text: 'VISIT',
      },
      {
        value: 3,
        text: 'ISOLATION',
      },
    ],
    column: [
      {
        field: 'name',
        name: 'Klinik',
      },
      {
        field: 'organizations',
        name: 'Organisasi',
        render: (orgs) => {
          return (orgs || []).map((org) => (
            <EuiBadge color='hollow'>{org.name}</EuiBadge>
          ));
        },
      },
    ],
    columnAddOn: [
      {
        field: 'title',
        name: 'Berkas',
      },
      {
        field: 'type',
        name: 'Tipe',
      },
    ],
    clinicType: [
      {
        value: '0',
        text: 'Pilih tipe klinik',
      },
      {
        value: '1',
        text: 'Reguler',
      },
      {
        value: '2',
        text: 'Homecare',
      },
      {
        value: '3',
        text: 'Drive Thru',
      },
      {
        value: '4',
        text: 'Vaksinasi',
      },
      {
        value: '5',
        text: 'Instan',
      },
    ],
    productData: [],
    productColumn: [
      {
        field: 'title',
        name: 'Nama Produk',
      },
      {
        field: 'fee',
        name: 'Tarif',
      },
      {
        field: 'id',
        name: '',
        render: (id) => (
          <EuiButtonToggle
            label='Tampilkan Log'
            iconType='trash'
            onChange={() => {
              this.deleteHomeCareProduct(id);
            }}
            isEmpty
            isIconOnly
            isLoading={this.state.deleteProductLoading}
          />
        ),
      },
    ],
    addProductColumn: [
      {
        field: 'title',
        name: 'Nama Produk',
      },
      {
        field: 'fee',
        name: 'Tarif',
      },
    ],
    // Form
    name: '',
    name_en: '',
    validationFields: {
      name: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
      name_en: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
      clinicTypeId: {
        type: 'number',
        isInvalid: false,
        errors: ['Silakan pilih terlebih dahulu'],
        isValidFunc: function (value) {
          return value > 0;
        },
      },
      clinicOrganizations: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
    },
    selectedOrganizationId: '',
    dataTypes: [
      {
        text: 'Teks',
        value: 'text',
      },
      {
        text: 'Gambar',
        value: 'image',
      },
      {
        text: 'Dokumen',
        value: 'document',
      },
    ],
    tags: [
      {
        text: 'Registrasi',
        value: 'registration',
      },
      {
        text: 'Harian',
        value: 'daily',
      },
    ],
    icon: '',
    iconLoading: false,
    iconImage: '',
    clinicOrganizations: [],
  };

  componentDidMount = () => {
    this.page(1);
    this.loadOrganizations();
  };

  loadOrganizations = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
      };
      organizationService
        .getOrganizations(payload)
        .then((result) => {
          let options = [];
          for (let i in result.items) {
            options.push({
              value: result.items[i].id,
              label: result.items[i].name,
            });
          }
          this.setState({ organizations: options }, () => {
            resolve();
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            organizations: [],
          });
          reject(err);
        });
    });
  };
  handleChange = (e) => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true });
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };
    clinicService
      .getClinics(payload)
      .then((result) => {
        console.log(result);
        for (let i in result.items) {
          result.items[i].organizationId = result.items[i].organization_id;
          result.items[i].organizationName = result.items[i].organization_name;
          if (
            result.items[i].only_existing_patients === undefined ||
            result.items[i].only_existing_patients === null
          ) {
            result.items[i].onlyExistingPatients = false;
          } else {
            result.items[i].onlyExistingPatients =
              result.items[i].only_existing_patients;
          }
          result.items[i].humanReadableDuration =
            parseInt(result.items[i].duration, 10) / 60000;
          if (result.items[i].homecare_clinic_variant === 1) {
            result.items[i].homeCareClinicVariantId = 1;
            result.items[i].homeCareClinicVariantName = 'REMOTE';
          } else if (result.items[i].homecare_clinic_variant === 2) {
            result.items[i].homeCareClinicVariantId = 2;
            result.items[i].homeCareClinicVariantName = 'VISIT';
          } else if (result.items[i].homecare_clinic_variant === 3) {
            result.items[i].homeCareClinicVariantId = 3;
            result.items[i].homeCareClinicVariantName = 'ISOLATION';
          } else {
            result.items[i].homeCareClinicVariantName = 'Pilih variant';
          }
          if (result.items[i].type === 1) {
            result.items[i].clinicTypeId = '1';
            result.items[i].clinicTypeName = 'Reguler';
          } else if (result.items[i].type === 2) {
            result.items[i].clinicTypeId = '2';
            result.items[i].clinicTypeName = 'Homecare';
          } else if (result.items[i].type === 3) {
            result.items[i].clinicTypeId = '3';
            result.items[i].clinicTypeName = 'Drive Thru';
          } else if (result.items[i].type === 4) {
            result.items[i].clinicTypeId = '4';
            result.items[i].clinicTypeName = 'Vaksinasi';
          } else if (result.items[i].type === 5) {
            result.items[i].clinicTypeId = '5';
            result.items[i].clinicTypeName = 'Instan';
          } else {
            result.items[i].clinicTypeId = '0';
            result.items[i].clinicTypeName = 'Pilih tipe klinik';
          }
        }
        this.setState(
          {
            page: result.page,
            limit: result.limit,
            total: result.total,
            order: result.order,
            orderBy: result.orderBy,
            data: result.items,
            loading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
        });
      });
  };

  handleOnSearch = (value) => {
    this.setState({ search: value, loading: true });
    this.handleSearchDebounce();
  };

  handleSearchDebounce = debounce(() => {
    this.page(1);
  }, 1000);

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  add = () => {
    this.prepareNewItem();
    this.setState({
      showListPatientsButton: false,
    });
  };
  addFile = () => {
    this.setState(
      {
        id: '',
        name: '',
        name_en: '',
        organizationId: '',
        organizationName: '',
        prioritize: false,
        onlyExistingPatients: false,
        showModalImport: true,
        isNewItem: true,
        clinicOrganizations: [],
      },
      () => {
        this.loadOrganizations();
      }
    );
  };

  submitFile = () => {
    const { clinicOrganizations, file } = this.state;
    clinicService
      .import({ organizationIds: clinicOrganizations, file })
      .then((response) => {
        swal({
          title: '',
          type: 'success',
          text: 'Data klinik berhasil diimpor',
        });
        this.setState(
          {
            file: {},
            selectedOrganizationId: '',
            organizations: [],
          },
          () => {
            this.closeModalImport();
            this.page(1);
          }
        );
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          swal({
            title: '',
            type: 'success',
            text: 'Berkas CSV gagal diimpor. Silakan periksa kembali berkas CSV Anda.',
          });
        }
        this.closeModalImport();
      });
  };

  save = () => {
    let state = { ...this.state };
    this.setState({ saveLoading: true }, () => {
      Utils.validateFields(state).then((result) => {
        this.setState({ validationFields: result.validationFields });
        this.forceUpdate();
        if (!result.isValid) {
          console.log(result);
          return;
        }

        let payload = {
          name: this.state.name,
          name_en: this.state.name_en,
          organizations: (this.state.clinicOrganizations || []).map((v) => ({
            id: v.value,
          })),
          prioritize: this.state.prioritize,
          only_existing_patients: this.state.onlyExistingPatients,
          type: parseInt(this.state.clinicTypeId, 10),
          homecare_clinic_variant: parseInt(
            this.state.homeCareClinicVariantId,
            10
          ),
          icon_image: this.state.iconImage,
          external_id: this.state.externalId,
        };
        if (this.state.id && this.state.id.length > 0) {
          payload.id = this.state.id;
          // Update existing
          clinicService
            .update(payload)
            .then((result) => {
              this.setState({ saveLoading: false, loading: true });
              this.closeModal();
              this.page(1);
            })
            .catch((err) => {
              this.setState({ saveLoading: false });
              errorService.handle(err);
            });
        } else {
          // Create new one
          clinicService
            .create(payload)
            .then((result) => {
              this.setState({ saveLoading: false, loading: true });
              this.closeModal();
              this.page(1);
            })
            .catch((err) => {
              this.setState({ saveLoading: false });
              errorService.handle(err);
            });
        }
      });
    });
  };

  delete = () => {
    if (!(this.state.id && this.state.id.length > 0)) {
      return;
    }
    if (!window.confirm('Apakah Anda yakin ingin menghapus item ini?')) {
      return;
    }
    clinicService
      .delete(this.state.id)
      .then((result) => {
        this.setState({ saveLoading: false, loading: true });
        this.closeModal();
        this.page(1);
      })
      .catch((err) => {
        this.setState({ saveLoading: false });
        errorService.handle(err);
      });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      id: '',
      name: '',
      name_en: '',
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      isNewItem: false,
      id: '',
      name: '',
      name_en: '',
      prioritize: false,
      onlyExistingPatients: false,
      addOnRegistration: false,
      title: '',
      priority: '',
      clinicTypeId: '0',
      extraDataType: 'text',
      tag: 'registration',
      mandatory: false,
      chatbotQuestion: '',
      extraDataId: '',
      extraData: [],
      loading: false,
      iconImage: '',
      icon: '',
      externalId: '',
    });
  };
  closeModalImport = () => {
    this.setState({ showModalImport: false });
  };
  closeModalAddOnRegistration = () => {
    this.setState({
      showModalAddOnRegistration: false,
      extraDataId: '',
    });
  };
  onChangeFile = (file) => {
    this.setState({ file });
  };

  onItemClick = (item) => {
    this.setState(
      {
        id: item.id,
        organizationId: item.organizationId,
        organizationName: item.organizationName,
        name: item.name,
        name_en: item.nameEn,
        prioritize: item.prioritize,
        onlyExistingPatients: item.onlyExistingPatients,
        homeCareClinicVariantId: item.homeCareClinicVariantId,
        homeCareClinicVariantName: item.homeCareClinicVariantName,
        clinicTypeId: item.clinicTypeId,
        clinicTypeName: item.clinicTypeName,
        type: item.type,
        showListPatientsButton: true,
        iconImage: item.iconImage,
        externalId: item.externalId,
        clinicOrganizations: (item.organizations || []).map((v) => ({
          value: v.id,
          label: v.name,
        })),
      },
      () => {
        if (item.type === 2) {
          this.pageProducts();
        }
        this.pageRegistration();
      }
    );
    if (
      item.iconImage !== '00000000-0000-0000-0000-000000000000' &&
      item.iconImage !== '' &&  item.iconImage !== undefined
    ) {
      clinicService
        .getFile(item.iconImage, true)
        .then((result) => {
          console.log(result);
          this.setState({
            iconImage: item.iconImage,
            icon: result.base64,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    this.setState({
      showModal: true,
    });
  };

  onOrganizationChange = (e) => {
    let value = e.target.value || null;
    console.log(value);
    let name = '';
    for (let i in this.state.organizations) {
      if (this.state.organizations[i].value === value) {
        name =
          this.state.organizations[i].name || this.state.organizations[i].text;
      }
    }
    this.setState({
      organizationId: value,
      organizationName: name,
    });
  };

  onHomeCareClinicVariantChange = (e) => {
    let value = e.target.value || null;
    let name = '';
    for (let i in this.state.homeCareClinicVariant) {
      if (this.state.homeCareClinicVariant[i].value === value) {
        name =
          this.state.homeCareClinicVariant[i].name ||
          this.state.homeCareClinicVariant[i].text;
      }
    }
    this.setState({
      homeCareClinicVariantId: value,
      homeCareClinicVariantName: name,
    });
  };

  onClinicTypeChange = (e) => {
    let value = e.target.value || null;
    let name = '';
    for (let i in this.state.clinicType) {
      if (this.state.clinicType[i].value === value) {
        name = this.state.clinicType[i].name || this.state.clinicType[i].text;
      }
    }
    this.setState({
      clinicTypeId: value,
      clinicTypeName: name,
    });
  };

  prepareNewItem = () => {
    this.setState(
      {
        id: '',
        name: '',
        name_en: '',
        organizationId: '',
        organizationName: '',
        showModal: true,
        isNewItem: true,
        clinicTypeId: '0',
        homeCareClinicVariantId: '0',
        clinicOrganizations: [],
      },
      () => {
        this.loadOrganizations();
      }
    );
  };

  dataTypeChange = (e) => {
    if (!(e && e.target && e.target.value)) {
      return;
    }
    let value = e.target.value || null;
    this.setState({
      extraDataType: value,
    });
  };

  tagChange = (e) => {
    if (!(e && e.target && e.target.value)) {
      return;
    }
    let value = e.target.value || null;
    this.setState({
      tag: value,
    });
  };

  onAddOnClick = (item) => {
    this.setState(
      {
        extraDataId: item.id,
        title: item.title,
        mandatory: item.mandatory || false,
        type: item.type,
        priority: item.priority,
        tag: item.tag,
        chatbotQuestion: item.chatbot_question,
        showModalAddOnRegistration: true,
        externalId: item.external_id,
      },
      () => this.pageRegistration()
    );
  };

  pageRegistration = () => {
    this.setState({ extraDataLoading: true });
    extraDataService
      .getExtraData(this.state.id)
      .then((result) => {
        if (result !== null) {
          this.setState(
            {
              addOnRegistration: true,
              extraData: result,
              extraDataLoading: false,
            },
            () => {
              this.forceUpdate();
            }
          );
        } else {
          this.setState({
            addOnRegistration: false,
            extraDataLoading: false,
            extraData: [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          extraDataLoading: false,
          extraData: [],
        });
      });
  };

  pageProducts = () => {
    this.setState({ productLoading: true });
    productService
      .getProductsByHomecareClinicId(this.state.id)
      .then((result) => {
        if (result !== null) {
          for (let i in result.items) {
            result.items[i].fee = 'Rp ' + result.items[i].price.units;
          }
          this.setState(
            {
              productData: result.items,
              productLoading: false,
            },
            () => {
              this.forceUpdate();
            }
          );
        } else {
          this.setState({
            productLoading: false,
            productData: [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          productLoading: false,
          productData: [],
        });
      });
  };

  deleteHomeCareProduct = (id) => {
    let payload = {};
    payload.products = [];
    this.setState({ deleteProductLoading: true });
    for (let i in this.state.productData) {
      if (this.state.productData[i].id === id) {
        continue;
      }
      payload.products.push(this.state.productData[i].id);
    }
    payload.clinic_id = this.state.id;
    productService
      .updateProductsHomeCareClinic(payload)
      .then((result) => {
        this.setState({ deleteProductLoading: false });
        this.pageProducts();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ deleteProductLoading: false });
        this.pageProducts();
      });
  };

  addHomeCareProduct = (item) => {
    let payload = {};
    payload.products = [];
    this.setState({ addProductLoading: true });
    for (let i in this.state.productData) {
      payload.products.push(this.state.productData[i].id);
    }
    payload.products.push(item.id);
    payload.clinic_id = this.state.id;
    productService
      .updateProductsHomeCareClinic(payload)
      .then((result) => {
        this.setState({ addProductLoading: false, modalAddProduct: false });
        this.pageProducts();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ addProductLoading: false, modalAddProduct: false });
        this.pageProducts();
      });
  };

  pageAllProducts = (page) => {
    page = page || 1;
    this.setState({ addProductLoading: true });
    let payload = {
      page: page,
      limit: this.state.addproductLimit,
      order: this.state.addProductOrder,
    };
    productService
      .getAllProducts(payload)
      .then((result) => {
        let items = [];
        for (let i in result.items) {
          result.items[i].fee = `Rp ${result.items[i].price.units}`;
          let found = false;
          for (let j in this.state.productData) {
            if (result.items[i].id === this.state.productData[j].id) {
              found = true;
              break;
            }
          }
          if (found) {
            continue;
          }
          items.push(result.items[i]);
        }
        this.setState(
          {
            addProductPage: result.page,
            addProductLimit: result.limit,
            addProductTotal: result.total,
            addProductOrder: result.order,
            addProductData: items || [],
            addProductLoading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          addProductLoading: false,
          addProductData: [],
        });
      });
  };

  nextPageAllProducts = () => {
    this.page(this.state.productPage + 1);
  };

  prevPageAllProducts = () => {
    this.page(this.state.productPage - 1);
  };

  saveRegistration = () => {
    console.log('allo');
    let state = { ...this.state };
    this.setState({ saveLoading: true }, () => {
      Utils.validateFields(state).then((result) => {
        this.setState({ validationFields: result.validationFields });
        this.forceUpdate();
        if (!result.isValid) {
          return;
        }
        let payload = {
          title: this.state.title,
          type: this.state.extraDataType,
          priority: parseInt(this.state.priority),
          tag: this.state.tag,
          mandatory: JSON.parse(this.state.mandatory),
          chatbot_question: this.state.chatbotQuestion,
          clinic_id: this.state.id,
          id: this.state.extraDataId,
        };
        console.log(payload);
        if (this.state.extraDataId && this.state.extraDataId.length > 0) {
          payload.id = this.state.extraDataId;
          // Update existing
          extraDataService
            .update(payload)
            .then((result) => {
              this.setState({ saveLoading: false, loading: true });
              this.closeModalAddOnRegistration();
              this.pageRegistration();
            })
            .catch((err) => {
              this.setState({ saveLoading: false });
              errorService.handle(err);
            });
        } else {
          // Create new one
          extraDataService
            .create(payload)
            .then((result) => {
              this.setState({ saveLoading: false, loading: true });
              this.closeModalAddOnRegistration();
              this.pageRegistration();
            })
            .catch((err) => {
              this.setState({ saveLoading: false });
              errorService.handle(err);
            });
        }
      });
    });
  };

  deleteRegistration = () => {
    if (!(this.state.extraDataId && this.state.extraDataId.length > 0)) {
      return;
    }
    if (!window.confirm('Apakah Anda yakin ingin menghapus item ini?')) {
      return;
    }
    extraDataService
      .delete(this.state.extraDataId)
      .then((result) => {
        this.setState({ saveLoading: false, loading: true });
        this.closeModalAddOnRegistration();
        this.pageRegistration();
      })
      .catch((err) => {
        this.setState({ saveLoading: false });
        errorService.handle(err);
      });
  };

  onIconChange = (files) => {
    if (files.length < 1) {
      this.setState({ icon: null });
      return;
    }
    this.setState(
      {
        iconLoading: true,
      },
      () => {
        clinicService
          .uploadIconFile(files[0])
          .then((result) => {
            this.setState({ iconImage: result });
            return clinicService.getFile(result);
          })
          .then((result) => {
            console.log(result);
            this.setState({ icon: result.base64, iconLoading: false });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  render() {
    return (
      <>
        <EuiPage className='class'>
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
            <TablePage
              title={'Klinik'}
              placeholder={'Cari'}
              searchComp={
                <EuiFlexItem>
                  <EuiFieldText
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        this.handleOnSearch(this.state.search);
                      }
                    }}
                    value={this.state.search}
                    onChange={(e) => this.handleOnSearch(e.target.value)}
                    placeholder={this.props.placeholder}
                    append={
                      <EuiButtonEmpty
                        onClick={() => this.handleOnSearch(this.state.search)}
                      >
                        Cari
                      </EuiButtonEmpty>
                    }
                  />
                </EuiFlexItem>
              }
              buttonLabel={'Tambah'}
              buttonFunc={() => this.add()}
              buttonImportLabel={'Import'}
              buttonImportFunc={() => this.addFile()}
              data={this.state.data}
              column={this.state.column}
              disablePagination={this.state.loading}
              loading={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
            />
          </EuiPageBody>
        </EuiPage>
        {this.state.showDeleteModal ? (
          <EuiOverlayMask>
            <EuiConfirmModal
              title='Hapus'
              onCancel={this.closeDeleteModal}
              onConfirm={this.closeDeleteModal}
              cancelButtonText='Batal'
              confirmButtonText='Hapus'
              buttonColor='danger'
              defaultFocusedButton='confirm'
            >
              <p>Apakah anda ingin menghapus item ini ?</p>
            </EuiConfirmModal>
          </EuiOverlayMask>
        ) : null}
        {this.state.showModal && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => this.closeModal()}
              initialFocus='[name=popswitch]'
              style={{ maxWidth: 400 }}
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>Klinik</EuiModalHeaderTitle>
                <Link
                  onClick={() => {
                    localStorage.setItem('clinicName', this.state.name);
                  }}
                  to={{ pathname: `/clinic/patients/${this.state.id}` }}
                >
                  {this.state.showListPatientsButton && (
                    <EuiButton>Lihat Daftar Pasien</EuiButton>
                  )}
                </Link>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow
                    label='Organisasi'
                    isInvalid={
                      this.state.validationFields['clinicOrganizations']
                        .isInvalid
                    }
                    error={
                      this.state.validationFields['clinicOrganizations']
                        .isInvalid &&
                      this.state.validationFields['clinicOrganizations'].errors
                    }
                  >
                    <EuiComboBox
                      aria-label='organization-label'
                      placeholder='Pilih Organisasi'
                      options={this.state.organizations}
                      selectedOptions={this.state.clinicOrganizations}
                      onChange={(v) =>
                        this.setState({ clinicOrganizations: v })
                      }
                      isClearable={true}
                      autoFocus
                    />
                  </EuiFormRow>
                  {this.state.clinicOrganizations &&
                    this.state.clinicOrganizations.length > 0 && (
                      <div style={{ marginTop: 15 }}>
                        <EuiFormRow
                          label='Nama'
                          isInvalid={
                            this.state.validationFields['name'].isInvalid
                          }
                          error={
                            this.state.validationFields['name'].isInvalid &&
                            this.state.validationFields['name'].errors
                          }
                        >
                          <EuiFieldText
                            placeholder='Nama'
                            value={this.state.name}
                            name='name'
                            onChange={this.handleChange}
                            aria-label='Nama'
                          />
                        </EuiFormRow>
                        <EuiFormRow
                          label='Nama dalam bahasa inggris'
                          isInvalid={
                            this.state.validationFields['name_en'].isInvalid
                          }
                          error={
                            this.state.validationFields['name_en'].isInvalid &&
                            this.state.validationFields['name_en'].errors
                          }
                        >
                          <EuiFieldText
                            placeholder='Nama dalam bahasa inggris'
                            value={this.state.name_en}
                            name='name_en'
                            onChange={this.handleChange}
                            aria-label='name-en'
                          />
                        </EuiFormRow>
                        <EuiFormRow label='Id Eksternal'>
                          <EuiFieldText
                            placeholder='Id Eksternal'
                            value={this.state.externalId}
                            name='externalId'
                            onChange={this.handleChange}
                            aria-label='Id Eksternal'
                          />
                        </EuiFormRow>
                        <EuiFormRow
                          label='Tipe Klinik'
                          isInvalid={
                            this.state.validationFields['clinicTypeId']
                              .isInvalid
                          }
                          error={
                            this.state.validationFields['clinicTypeId']
                              .isInvalid &&
                            this.state.validationFields['clinicTypeId'].errors
                          }
                        >
                          <EuiSelect
                            placeholder='clinicTypeId'
                            options={this.state.clinicType}
                            value={this.state.clinicTypeId}
                            name='clinicTypeId'
                            onChange={this.onClinicTypeChange}
                            aria-label='clinicTypeId'
                          />
                        </EuiFormRow>
                        {this.state.clinicTypeId === '2' && (
                          <EuiFormRow label='Klinik Homecare Variant'>
                            <EuiSelect
                              placeholder='HomeCareClinicVariant'
                              options={this.state.homeCareClinicVariant}
                              value={this.state.homeCareClinicVariantId}
                              name='homeCareClinicVariant'
                              onChange={this.onHomeCareClinicVariantChange}
                              aria-label='HomeCareClinicVariant'
                            />
                          </EuiFormRow>
                        )}
                        <EuiFormRow label='Prioritas'>
                          <EuiSwitch
                            label=''
                            onChange={() => {
                              this.setState({
                                prioritize: !this.state.prioritize,
                              });
                            }}
                            checked={this.state.prioritize}
                          />
                        </EuiFormRow>
                        <EuiFormRow label='Hanya untuk pasien lama'>
                          <EuiSwitch
                            label=''
                            onChange={() => {
                              this.setState({
                                onlyExistingPatients:
                                  !this.state.onlyExistingPatients,
                              });
                            }}
                            checked={this.state.onlyExistingPatients}
                          />
                        </EuiFormRow>
                        <EuiFormRow label='Icon'>
                          <div>
                            {this.state.iconLoading && (
                              <p>
                                <EuiLoadingSpinner size='xl' />
                              </p>
                            )}
                            {!this.state.iconLoading && (
                              <p style={{ fontSize: 12, marginBottom: 4 }}>
                                Gunakan rasio gambar persegi atau ukuran 300x300
                                piksel.
                              </p>
                            )}
                            {!this.state.iconLoading &&
                              this.state.icon &&
                              this.state.icon.length > 0 && (
                                <EuiImage
                                  hasShadow
                                  allowFullScreen
                                  src={`data:image/jpeg;base64,${this.state.icon}`}
                                  size={330}
                                />
                              )}
                            <EuiFilePicker
                              name='icon'
                              id='iconFilePicker'
                              onChange={(files) => {
                                this.onIconChange(files);
                              }}
                            />
                          </div>
                        </EuiFormRow>
                        {!this.state.isNewItem && (
                          <EuiFormRow label='Data Tambahan Registrasi Pasien'>
                            <EuiFlexGroup wrap gutterSize='s'>
                              <EuiFlexItem grow={false}>
                                <EuiSwitch
                                  label=''
                                  onChange={() => {
                                    this.setState({
                                      addOnRegistration:
                                        !this.state.addOnRegistration,
                                    });
                                  }}
                                  checked={this.state.addOnRegistration}
                                  disabled={this.state.extraDataLoading}
                                />
                              </EuiFlexItem>
                              <EuiFlexItem grow={false}>
                                {this.state.extraDataLoading && (
                                  <EuiLoadingSpinner size='m' />
                                )}
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          </EuiFormRow>
                        )}
                        {this.state.addOnRegistration && (
                          <div style={{ marginTop: 15 }}>
                            <TablePage
                              data={this.state.extraData}
                              column={this.state.columnAddOn}
                              onItemClick={this.onAddOnClick}
                              loading={this.state.extraDataLoading}
                            />
                            <div style={{ marginTop: 15, marginBottom: 15 }}>
                              <EuiFlexItem grow={false}>
                                <EuiButton
                                  disabled={this.state.extraDataLoading}
                                  onClick={() =>
                                    this.setState({
                                      showModalAddOnRegistration:
                                        !this.state.showModalAddOnRegistration,
                                      title: '',
                                      priority: '',
                                      mandatory: false,
                                      chatbotQuestion: '',
                                      extraDataType: 'text',
                                      tag: 'registration',
                                    })
                                  }
                                >
                                  Tambah
                                </EuiButton>
                              </EuiFlexItem>
                            </div>
                          </div>
                        )}
                        {!this.state.isNewItem &&
                          this.state.clinicTypeId === '2' && (
                            <div>
                              <EuiFormRow label='List Produk'>
                                <TablePage
                                  data={this.state.productData}
                                  column={this.state.productColumn}
                                  loading={this.state.productLoading}
                                />
                              </EuiFormRow>
                              <div style={{ marginTop: 15, marginBottom: 15 }}>
                                <EuiFlexItem grow={false}>
                                  <EuiButton
                                    disabled={this.state.extraDataLoading}
                                    onClick={() =>
                                      this.setState(
                                        {
                                          modalAddProduct:
                                            !this.state.modalAddProduct,
                                        },
                                        () => this.pageAllProducts(1)
                                      )
                                    }
                                  >
                                    Tambah Produk
                                  </EuiButton>
                                </EuiFlexItem>
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                </EuiForm>
              </EuiModalBody>

              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => this.closeModal()}>
                  Batal
                </EuiButtonEmpty>
                {this.state.id && (
                  <EuiButton
                    color={'danger'}
                    onClick={() => this.delete()}
                    fill
                  >
                    Hapus
                  </EuiButton>
                )}
                <EuiButton onClick={() => this.save()} fill>
                  Simpan
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
        {this.state.showModalImport && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => this.closeModalImport()}
              initialFocus='[name=popswitch]'
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>Import Data</EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow
                    label='Organisasi'
                    isInvalid={
                      this.state.validationFields['clinicOrganizations']
                        .isInvalid
                    }
                    error={
                      this.state.validationFields['clinicOrganizations']
                        .isInvalid &&
                      this.state.validationFields['clinicOrganizations'].errors
                    }
                  >
                    <EuiComboBox
                      aria-label='organization-label'
                      placeholder='Pilih Organisasi'
                      options={this.state.organizations}
                      selectedOptions={this.state.clinicOrganizations}
                      onChange={(v) =>
                        this.setState({ clinicOrganizations: v })
                      }
                      isClearable={true}
                      autoFocus
                    />
                  </EuiFormRow>
                  <h5 style={{ margin: 12 }}>Pilih File CSV</h5>
                  <EuiFilePicker
                    id='asdf2'
                    initialPromptText='Pilih atau seret dan lepas berkas'
                    onChange={(file) => {
                      this.onChangeFile(file);
                    }}
                    display='large'
                    aria-label='Use aria labels when no actual label is in use'
                  />
                </EuiForm>
              </EuiModalBody>

              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => this.closeModalImport()}>
                  Batal
                </EuiButtonEmpty>
                <EuiButton
                  fill
                  disabled={
                    (this.state.clinicOrganizations &&
                      this.state.clinicOrganizations.length < 1) ||
                    !this.state.file ||
                    this.state.file?.length < 1
                  }
                  onClick={() => this.submitFile()}
                >
                  Proses
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
        {this.state.modalAddProduct && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => {
                this.setState({ modalAddProduct: false });
              }}
              initialFocus='[name=popswitch]'
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>Tambah produk</EuiModalHeaderTitle>
              </EuiModalHeader>
              <EuiModalBody>
                <TablePage
                  page={this.state.addProductPage}
                  total={this.state.addProductTotal}
                  limit={this.state.addproductLimit}
                  data={this.state.addProductData}
                  column={this.state.addProductColumn}
                  loading={this.state.addProductLoading}
                  toPage={this.pageAllProducts}
                  onItemClick={this.addHomeCareProduct}
                />
              </EuiModalBody>
              <EuiModalFooter>
                <EuiButtonEmpty
                  onClick={() => {
                    this.setState({ modalAddProduct: false });
                  }}
                >
                  Batal
                </EuiButtonEmpty>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
        {this.state.showModalAddOnRegistration && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => this.closeModalAddOnRegistration()}
              initialFocus='[name=popswitch]'
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>Data Tambahan Pasien</EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow label='Judul'>
                    <EuiFieldText
                      placeholder='Hasil SWAB / KTP, dll'
                      value={this.state.title}
                      name='title'
                      onChange={this.handleChange}
                      aria-label='title'
                    />
                  </EuiFormRow>
                  <EuiFormRow label='Jenis'>
                    <EuiSelect
                      placeholder='Jenis'
                      options={this.state.dataTypes}
                      value={this.state.extraDataType}
                      name='text'
                      onChange={this.dataTypeChange}
                      aria-label='Jenis'
                    />
                  </EuiFormRow>
                  <EuiFormRow label='Prioritas'>
                    <EuiFieldNumber
                      placeholder='0'
                      value={this.state.priority}
                      onChange={this.handleChange}
                      name='priority'
                      aria-label='Urutan'
                    />
                  </EuiFormRow>
                  <EuiFormRow label='Tag'>
                    <EuiSelect
                      placeholder='Tag'
                      options={this.state.tags}
                      value={this.state.tag}
                      name='text'
                      onChange={this.tagChange}
                      aria-label='Tag'
                    />
                  </EuiFormRow>
                  <EuiFormRow label='Wajib ada ?'>
                    <EuiSwitch
                      label=''
                      onChange={() => {
                        this.setState({
                          mandatory: !this.state.mandatory,
                        });
                      }}
                      checked={this.state.mandatory}
                    />
                  </EuiFormRow>
                  <EuiFormRow label='Pertanyaan di chatbot ?'>
                    <EuiFieldText
                      placeholder='Pertanyaan'
                      value={this.state.chatbotQuestion}
                      name='chatbotQuestion'
                      onChange={this.handleChange}
                      aria-label='chatbotQuestion'
                    />
                  </EuiFormRow>
                </EuiForm>
              </EuiModalBody>

              <EuiModalFooter>
                <EuiButtonEmpty
                  onClick={() => this.closeModalAddOnRegistration()}
                >
                  Batal
                </EuiButtonEmpty>
                {this.state.extraDataId && this.state.extraDataId.length > 0 ? (
                  <>
                    <EuiButton
                      color={'danger'}
                      onClick={() => this.deleteRegistration()}
                      fill
                    >
                      Hapus
                    </EuiButton>
                    <EuiButton
                      fill
                      isLoading={this.state.saveLoading}
                      onClick={() => this.saveRegistration()}
                    >
                      Update
                    </EuiButton>
                  </>
                ) : (
                  <EuiButton
                    fill
                    isLoading={this.state.saveLoading}
                    onClick={() => this.saveRegistration()}
                  >
                    Tambah
                  </EuiButton>
                )}
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
      </>
    );
  }
}

export default Clinic;
