import React, { Component } from 'react';
import { debounce } from 'lodash';
import {
  EuiPage,
  EuiPageBody,
  EuiFlexItem,
  EuiFieldText,
  EuiButtonEmpty,
  EuiPopover,
  EuiPinnableListGroup,
  EuiCollapsibleNavGroup,
  EuiPinnableListGroupItemProps,
  EuiTab,
  EuiPageContent,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiButtonIcon,
  EuiPageContentHeader,
  EuiTabs,
  EuiPageContentBody,
  EuiFlexGroup,
  EuiSelect,
  EuiDatePicker,
  EuiOverlayMask,
  EuiModal,
  EuiText,
  EuiModalHeaderTitle,
  EuiModalHeader,
  EuiModalFooter,
  EuiButton,
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import PharmaciesService from '../services/Pharmacies';
import moment from 'moment';
import Blobs from '../services/Blob';
import Patients from '../services/Patients';
import downloadjs from 'downloadjs';

const pharmaciesService = new PharmaciesService();
const blobService = new Blobs();
const patientService = new Patients();


const DetailResepModal = (props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const showModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const renderItem = () => {
    let list = [];
    for (let i in props.item.items) {
      const listItem: EuiPinnableListGroupItemProps[] = [
        { label: `Nama Obat: ${props.item.items[i].medicine_name}` },
        { label: `Size unit (unit terkecilnya): ${props.item.items[i].dose_size_unit}`},
        { label: `Numero: ${props.item.items[i].numero}` },
        { label: `Dosis: ${props.item.items[i].dose_frequency}` },
        { label: `Alasan/petunjuk: ${props.item.items[i].instruction}` },
        { label: `Catatan obat: ${props.item.items[i].note}` },
      ];
      list.push(
         <EuiCollapsibleNavGroup
                title={props.item.items[i].medicine_name}
                isCollapsible={true}
                initialIsOpen={true}>
                <EuiPinnableListGroup
                  listItems={listItem}
                  maxWidth="none"
                  color="dark"
                  gutterSize="none"
                  size="s"
                /></EuiCollapsibleNavGroup>
      );
    }
    return list;
  };

  let modal;
    if (isModalVisible) {
      modal = (
        <>
        <EuiOverlayMask>
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Detail Resep Obat</EuiModalHeaderTitle>
          </EuiModalHeader>
          <div style={{overflowY: 'auto'}}>
            <EuiText>
            {renderItem()}
            </EuiText>
            </div>
            <EuiText style={{padding: '0 15px',maxWidth: '75%', margin:'10px 0 -15px 0'}}>
            <h4>Catatan Resep:</h4>
            <p>{props.item.note ? props.item.note : '-'}</p>
            </EuiText>
          <EuiModalFooter style={{padding:'0 24px 24px !important'}}>
            <EuiButton onClick={closeModal} fill>
              Tutup
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
      </>
      );
    }
    return (
      <>
        <EuiButtonEmpty
                  size='xs'
                  disabled={!props.item.items}
                  className='btnList'
                  onClick={showModal}
                >
                  Lihat Resep
                </EuiButtonEmpty>
                
        {modal}
        </>
    );
}

class KimiaFarmaPharmacies extends Component {
  state = {
    page: 1,
    limit: 10,
    search: '',
    loading: false,
    orderBy: 'created_at',
    order: 'desc',
    pharmacy: 'kimiafarma',
    popOver: {},
    phoneNumberPatient: 0,
    column: [
      {
        field: 'created_at',
        name: 'Tanggal Pembuatan Resep',
        sortable: true,
      },
      {
        field: 'external_id',
        name: 'ID Transaksi',
      },
      {
        field: 'author_name',
        name: 'Nama Dokter',
      },
      {
        field: 'expired_at',
        name: 'Masa Berlaku',
      },
      {
        field: 'patient_name',
        name: 'Nama Pasien',
      },
      {
        field: 'pharmacy_name',
        name: 'Nama Apotek',
      },
      {
        field: 'pharmacy_address',
        name: 'Lokasi Apotek',
        sortable: false,
      },
      {
        field: 'status',
        name: 'Status',
      },
      {
        field: 'receipt_blob_id',
        name: 'Aksi',
        width: '8%',
        render: (prescription_receipt, item) => (
          <>
          <EuiPopover
            ownFocus={false}
            button={
              <EuiButtonIcon
                onClick={() => this.onPopOverClick(item.id)}
                iconType='boxesHorizontal'
                aria-label='option'
              />
            }
            isOpen={this.state.popOver[item.id]?.isOpen}
            closePopover={() => this.onPopOverClick(item.id)}
            anchorPosition='upCenter'
            panelPaddingSize="none"
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <EuiButtonEmpty
                  size='xs'
                  className='btnList'
                  onClick={()=> window.open("https://wa.me/"+item.phoneNumberPatient, "_blank")}
                >
                  Kontak WhatsApp
                </EuiButtonEmpty>
                <DetailResepModal item={item}/>
                <detailResepModal></detailResepModal>
                <EuiButtonEmpty
                  size='xs'
                  className='btnList'
                  onClick={() =>
                    this.props.history.push({
                      pathname: '/events',
                      state: { secondary_key: item.id },
                    })
                  }
                >
                  Log Events
                </EuiButtonEmpty>
                <EuiButtonEmpty
                  size='xs'
                  className='btnList'
                  onClick={() =>
                    this.props.history.push({
                      pathname: '/thirdparty-logs',
                      state: {
                        key: 'payload',
                        keyword: item.id,
                      },
                    })
                  }
                >
                  Log Bridging
                </EuiButtonEmpty>
                <EuiButtonEmpty
                  size='xs'
                  className='btnList'
                  disabled={!prescription_receipt}
                  onClick={() => this.downloadReceipt(prescription_receipt)}
                >
                  Unduh Kwitansi
                </EuiButtonEmpty>
            </div>
          </EuiPopover>
        </>
        ),
        sortable: false,
      },
    ],
    selectedTabId: 'all',
    tabs: [
      {
        id: 'all',
        name: 'Semua',
        disabled: false,
      },
      {
        id: 'CREATED',
        name: 'Resep dibuat',
        disabled: false,
      },
      {
        id: 'INVOICE',
        name: 'Invoice dibuat',
        disabled: false,
      },
      {
        id: 'PAID',
        name: 'Resep Dibayar',
        disabled: false,
      },
      {
        id: 'PROCESSED',
        name: 'Resep diproses',
        disabled: false,
      },
      {
        id: 'READY_FOR_PICKUP',
        name: 'Siap diambil',
        disabled: false,
      },
      {
        id: 'DONE',
        name: 'Resep diterima',
        disabled: false,
      },
    ],
    dateRangeOptions: [
      {
        text: 'Semua Tanggal',
        value: 'all',
      },
      {
        text: 'Tanggal Pembuatan Resep',
        value: 'created_at',
      },
      {
        text: 'Tanggal Masa Berlaku',
        value: 'expired_at',
      },
    ],
    searchOptions: [
      {
        text: 'Dokter',
        value: 'author_name',
      },
      {
        text: 'Pasien',
        value: 'patient_name',
      },
      {
        text: 'Nama Apotek',
        value: 'pharmacy_name',
      },
      {
        text: 'ID Session',
        value: 'session_id',
      },
    ],
    searchString: this.props.location.state?.searchString || '',
    searchBy: this.props.location.state?.searchBy ||'author_name',
    searchStartDate: moment(),
    searchEndDate: moment(),
    specificDate: moment(),
    dateRange: 'all',
    data: [],
  };

  componentDidMount = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const status = queryParams.get("status")
    if (status){
      this.state.selectedTabId = status
    }
    this.page(1);
  };

  getPatientById = async (uuid,index) => {
    try {
      let patient = await patientService.getPatient(uuid)
      if (patient.phoneNumber) {
        this.state.data[index].phoneNumberPatient = patient.phoneNumber
      }
    } catch (e) {
      console.log(e);
    }
  };

  page = (page) => {
    page = page || 1;
    let payload = {
      page: page,
      limit: this.state.limit,
      order: this.state.order,
      orderBy: this.state.orderBy,
      pharmacy: this.state.pharmacy,
      search: this.state.searchString,
      searchBy: this.state.searchBy,
      searchDateBy: this.state.dateRange,
      searchStartDate: moment(this.state.searchStartDate).format('YYYY-MM-DD'),
      searchEndDate: moment(this.state.searchEndDate).format('YYYY-MM-DD'),
      status:
        this.state.selectedTabId === 'all' ? '' : this.state.selectedTabId,
    };

    if (this.state.dateRange === 'all') {
      console.log('DATE RANGE IS ALL');
      payload.searchDateBy = '';
      payload.searchEndDate = '';
      payload.searchStartDate = '';
    }

    console.log('sented payload ------> ', payload);

    pharmaciesService
      .getPharmacyPrescriptions(payload)
      .then((res) => {
        for (let i in res.items) {
          res.items[i].status = res.items[i].status || 0;
          res.items[i].phoneNumberPatient = 0;
          switch (res.items[i].status) {
            case 0:
              res.items[i].status = 'Resep telah dibuat';
              break;
            case 1:
              res.items[i].status = 'Invoice resep telah dibuat';
              break;
            case 2:
              res.items[i].status = 'Resep telah dibayar';
              break;
            case 3:
              res.items[i].status = 'Resep sedang diproses';
              break;
            case 4:
              res.items[i].status = 'Resep siap untuk diambil';
              break;
            case 10:
              res.items[i].status = 'Resep sudah diterima oleh pasien';
              break;
            default:
              res.items[i].status = 'Status tidak ditemukan';
              break;
          }

          res.items[i].created_at = res.items[i].created_at
            ? moment(res.items[i].created_at, 'YYYY-MM-DD HH:mm:ss').format(
                'DD MMMM YYYY HH:mm'
              )
            : '-';

          res.items[i].expired_at = res.items[i].expired_at
            ? moment(res.items[i].expired_at, 'YYYY-MM-DD HH:mm:ss').format(
                'DD MMMM YYYY HH:mm'
              )
            : '-';
        this.getPatientById(res.items[i].patient_id, i)
        }
        this.setState(
          {
            page: res.page,
            limit: res.limit,
            data: res.items,
            total: res.total,
            loading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };
  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  onPopOverClick = (id) => {
    let state = this.state;
    if (!state.popOver[id]) {
      state.popOver[id] = { isOpen: true };
    } else {
      state.popOver[id] = { isOpen: !state.popOver[id].isOpen };
    }
    this.setState(state);
  };

  downloadReceipt = (id) => {
    blobService
      .getBlob(id)
      .then((result) => {
        console.log(result);
        downloadjs(
          `data:${result.contentType};base64,${result.base64}`,
          `prescription_${id}.pdf`,
          `${result.contentType}`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onSelectedTabChanged = (id) => {
    this.setState({ loading: true, selectedTabId: id, searchDate: '' }, () => {
      this.page(1);
    });
  };

  renderTabs = () => {
    return this.state.tabs.map((tab, index) => (
      <EuiTab
        {...(tab.href && { href: tab.href, target: '_blank' })}
        onClick={() => this.onSelectedTabChanged(tab.id)}
        isSelected={tab.id === this.state.selectedTabId}
        disabled={tab.disabled}
        key={index}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  onDateRangeChange = (e) => {
    let value = e.target.value || null;
    this.setState(
      { dateRange: value, searchStartDate: moment(), searchEndDate: moment() },
      () => {
        if (value === 'all') {
          this.page(1);
        }
      }
    );
  };

  handleDateChange = (date) => {
    // in moment object format
    this.setState({ searchStartDate: date });
  };

  handleDateChangeEnd = (date) => {
    // in moment object format
    this.setState({ searchEndDate: date, loading: true }, () => {
      this.page(1);
    });
  };

  onSearchByChange = (e) => {
    let value = e.target.value || null;
    this.setState({ searchBy: value, searchString: '' });
  };

  searchStringChange = debounce((value) => {
    this.setState({ searchString: value, loading: true }, () => {
      this.page(1);
    });
  }, 1000);

  onSearchStringChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      this.setState({ searchString: '' });
    }
    this.setState({ searchString: value });
    this.searchStringChange(value);
  };

  renderFilterComponent = () => {
    return (
      <div>
        <div style={{ display: 'inline-block' }}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} style={{ marginRight: '-10px' }}>
              <EuiSelect
                style={{ display: 'inline-block !important' }}
                placeholder='Cari berdasarkan tanggal'
                options={this.state.dateRangeOptions}
                value={this.state.dateRange}
                name='dateRange'
                onChange={this.onDateRangeChange}
                aria-label='Cari berdasarkan tanggal'
              />
            </EuiFlexItem>
            {this.state.dateRange !== 'all' && (
              <EuiFlexGroup style={{ margin: '0px' }}>
                <EuiFlexItem grow={false}>
                  <EuiDatePicker
                    selected={this.state.searchStartDate}
                    onChange={this.handleDateChange}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <div style={{ marginTop: 10 }}>sampai</div>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiDatePicker
                    selected={this.state.searchEndDate}
                    onChange={this.handleDateChangeEnd}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
          </EuiFlexGroup>
        </div>
        <div style={{ display: 'inline-block', marginLeft: 30 }}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} style={{ marginRight: '-10px' }}>
              <EuiSelect
                style={{ display: 'inline-block !important' }}
                placeholder='Cari berdasarkan'
                options={this.state.searchOptions}
                value={this.state.searchBy}
                name='searchBy'
                onChange={this.onSearchByChange}
                aria-label='Cari berdasarkan'
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFieldText
                style={{ display: 'inline-block !important' }}
                placeholder='Cari...'
                name='searchString'
                onChange={this.onSearchStringChange}
                value={this.state.searchString}
                append={
                  <EuiButtonEmpty onClick={() => this.onSearchStringChange}>
                    Cari
                  </EuiButtonEmpty>
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </div>
    );
  };

  render = () => {
    const sorting = {
      sort: {
        field: this.state.orderBy,
        direction: this.state.order,
      },
    };

    return (
      <>
        <EuiPage className='class'>
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                  <EuiTitle>
                    <h2>Resep Obat Kimia Farma</h2>
                  </EuiTitle>
                </EuiPageContentHeaderSection>
              </EuiPageContentHeader>
              <EuiPageContentHeader>
                <EuiTabs>{this.renderTabs()}</EuiTabs>
              </EuiPageContentHeader>
              <EuiPageContentHeader>
                {this.renderFilterComponent()}
              </EuiPageContentHeader>
              <EuiPageContentBody>
                <EuiFlexItem>
                  <EuiPage className='class'>
                    <TablePage
                      data={this.state.data}
                      column={this.state.column}
                      disablePagination={this.state.loading}
                      page={this.state.page}
                      limit={this.state.limit}
                      total={this.state.total}
                      prev={this.prevPage}
                      next={this.nextPage}
                      toPage={this.page}
                      onItemClick={this.onItemClick}
                      loading={this.state.loading}
                      sort={sorting}
                      change={(v) => {
                        this.setState(
                          {
                            order: v.sort.direction,
                            loading: true,
                          },
                          () => this.page(this.state.page)
                        );
                      }}
                    />
                  </EuiPage>
                </EuiFlexItem>
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>
      </>
    );
  };
}

export default KimiaFarmaPharmacies;
