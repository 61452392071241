import React, { Component } from 'react';

import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiButtonEmpty,
  EuiFieldText,
  EuiPanel,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiDatePicker,
  EuiHorizontalRule,
  EuiSpacer,
  EuiCard,
  EuiDatePickerRange,
} from '@elastic/eui';
import SummariesService from '../services/Summaries';
import moment from 'moment';

const Summaries = new SummariesService();

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryPatient: '',
      queryDoctor: '',
      queryStaff: '',
      queryBill: '',

      // response data
      session_summary: {},
      prescription_summary: {},
      diagnostic_summary: {},

      startDate: moment(),
      endDate: moment(),
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchSummaries();
  }

  fetchSummaries = () => {
    let startDate = this.state.startDate
      .set({ hour: 0, minute: 0, second: 0 })
      .format('X');
    let endDate = this.state.endDate
      .set({ hour: 23, minute: 59, second: 59 })
      .format('X');

    Summaries.get(startDate, endDate)
      .then((response) => {
        console.log(response);
        this.setState({
          ...response,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const renderSummary = (title, items) => {
      return (
        <div>
          <EuiTitle size='xs'>
            <h1>{title}</h1>
          </EuiTitle>
          <EuiFlexGroup>
            {items.map((item, index) => {
              return (
                <EuiFlexItem key={index} grow={false} style={{ width: '12%' }}>
                  <EuiCard
                    style={{ backgroundColor: item.bgColor }}
                    title={this.state.loading ? 'Loading...' : item.title}
                    description={item.description}
                  />
                </EuiFlexItem>
              );
            })}
          </EuiFlexGroup>
        </div>
      );
    };

    return (
      <>
        <EuiPage>
          <EuiPageBody style={{ marginTop: '3%' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <EuiPageContent
                className='card-badge'
                style={{ width: '21%' }}
                betaBadgeLabel={'Pasien'}
                verticalPosition='center'
                horizontalPosition='center'
              >
                <EuiFieldText
                  placeholder='Nama pasien'
                  value={this.state.queryPatient}
                  onChange={(e) => {
                    this.setState({ queryPatient: e.target.value });
                  }}
                  append={
                    <EuiButtonEmpty
                      onClick={() => {
                        this.props.history.push({
                          pathname: '/patients',
                          search: '?q=' + this.state.queryPatient,
                        });
                      }}
                    >
                      Cari
                    </EuiButtonEmpty>
                  }
                />
              </EuiPageContent>

              <EuiPageContent
                className='card-badge'
                style={{ width: '21%' }}
                betaBadgeLabel={'Dokter'}
                verticalPosition='center'
                horizontalPosition='center'
              >
                <EuiFieldText
                  placeholder='Nama dokter'
                  value={this.state.queryDoctor}
                  onChange={(e) => {
                    this.setState({ queryDoctor: e.target.value });
                  }}
                  append={
                    <EuiButtonEmpty
                      onClick={() => {
                        this.props.history.push({
                          pathname: '/doctors',
                          search: '?q=' + this.state.queryDoctor,
                        });
                      }}
                    >
                      Cari
                    </EuiButtonEmpty>
                  }
                />
              </EuiPageContent>

              <EuiPageContent
                className='card-badge'
                style={{ width: '21%' }}
                betaBadgeLabel={'Staff'}
                verticalPosition='center'
                horizontalPosition='center'
              >
                <EuiFieldText
                  placeholder='Nama staff'
                  value={this.state.queryStaff}
                  onChange={(e) => {
                    this.setState({ queryStaff: e.target.value });
                  }}
                  append={
                    <EuiButtonEmpty
                      onClick={() => {
                        this.props.history.push({
                          pathname: '/assistants',
                          search: '?q=' + this.state.queryStaff,
                        });
                      }}
                    >
                      Cari
                    </EuiButtonEmpty>
                  }
                />
              </EuiPageContent>

              <EuiPageContent
                className='card-badge'
                style={{ width: '21%' }}
                betaBadgeLabel={'Tagihan'}
                verticalPosition='center'
                horizontalPosition='center'
              >
                <EuiFieldText
                  placeholder='Nomor tagihan'
                  value={this.state.queryBill}
                  onChange={(e) => {
                    this.setState({ queryBill: e.target.value });
                  }}
                  append={
                    <EuiButtonEmpty
                      onClick={() => {
                        this.props.history.push({
                          pathname: '/orders',
                          search: '?q=' + this.state.queryBill,
                        });
                      }}
                    >
                      Cari
                    </EuiButtonEmpty>
                  }
                />
              </EuiPageContent>
            </div>
            <EuiPanel
              paddingSize='s'
              style={{
                padding: '20px',
                marginTop: '15px',
                marginLeft: '35px',
                marginRight: '15px',
                textAlign: 'left',
              }}
            >
              <EuiTitle size='l'>
                <h2>Summary</h2>
              </EuiTitle>
              <EuiHorizontalRule />
              <EuiFlexGroup
                justifyContent='flexStart'
                style={{ marginLeft: 0, width: '500px' }}
              >
                <EuiDatePickerRange
                  fullWidth
                  startDateControl={
                    <EuiDatePicker
                      selected={this.state.startDate}
                      onChange={(date, e) =>
                        date &&
                        e &&
                        this.setState(
                          {
                            startDate: date,
                            endDate: this.state.endDate.isBefore(date)
                              ? date
                              : this.state.endDate,
                            loading: true,
                          },
                          () => this.fetchSummaries()
                        )
                      }
                      maxDate={moment()}
                      dateFormat={'dddd, DD MMMM YYYY'}
                    />
                  }
                  endDateControl={
                    <EuiDatePicker
                      selected={this.state.endDate}
                      minDate={this.state.startDate}
                      maxDate={moment()}
                      onChange={(date) =>
                        date &&
                        this.setState({ endDate: date, loading: true }, () =>
                          this.fetchSummaries()
                        )
                      }
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      dateFormat={'dddd, DD MMMM YYYY'}
                    />
                  }
                />
              </EuiFlexGroup>
              <EuiSpacer />
              {renderSummary('SESI', [
                {
                  bgColor: '#DEF5FF',
                  title: this.state.session_summary.all || 0,
                  description: 'Semua sesi',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.session_summary.new || 0,
                  description: 'Sesi baru',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.session_summary.active || 0,
                  description: 'Sesi aktif',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.session_summary.ongoing || 0,
                  description: 'Sesi ongoing',
                },
                {
                  bgColor: '#C5FFD5',
                  title: this.state.session_summary.ended || 0,
                  description: 'Tuntas',
                },
                {
                  bgColor: '#C5FFD5',
                  title: this.state.session_summary.done_via_siapdok || 0,
                  description: 'Tuntas via SiapDok',
                },
                {
                  bgColor: '#FFE0E0',
                  title: this.state.session_summary.refunded || 0,
                  description: 'Refund',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.session_summary.rescheduled || 0,
                  description: 'Reschedule',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.session_summary.disabled || 0,
                  description: 'Disabled',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.session_summary.unknown || 0,
                  description: 'Tidak diketahui',
                },
              ])}
              <EuiSpacer />
              {renderSummary('Peresepan', [
                {
                  bgColor: '#DEF5FF',
                  title: this.state.prescription_summary.all || 0,
                  description: 'Semua peresepan',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.prescription_summary.new || 0,
                  description: 'Baru',
                },
                {
                  bgColor: '#FFE0E0',
                  title:
                    this.state.prescription_summary.send_prescription_error ||
                    0,
                  description: 'Pengiriman resep gagal',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.prescription_summary.invoice || 0,
                  description: 'Tagihan resep belum dibayar',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.prescription_summary.paid || 0,
                  description: 'Tagihan resep sudah dibayar',
                },
                {
                  bgColor: '#FFE0E0',
                  title:
                    this.state.prescription_summary
                      .proceed_prescription_error || 0,
                  description: 'Proses resep gagal',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.prescription_summary.proceeded || 0,
                  description: 'Obat sedang diproses',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.prescription_summary.ready_for_pickup || 0,
                  description: 'Siap dipickup',
                },
                {
                  bgColor: '#C5FFD5',
                  title: this.state.prescription_summary.done || 0,
                  description: 'Resep Selesai',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.prescription_summary.unknown || 0,
                  description: 'Tidak diketahui',
                },
              ])}
              <EuiSpacer />
              {renderSummary('Uji lab', [
                {
                  bgColor: '#DEF5FF',
                  title: this.state.diagnostic_summary.all || 0,
                  description: 'Semua uji lab',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.diagnostic_summary.new || 0,
                  description: 'Baru',
                },
                {
                  bgColor: '#FFE0E0',
                  title:
                    this.state.diagnostic_summary.send_diagnostic_error || 0,
                  description: 'Pemesanan uji lab gagal',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.diagnostic_summary.invoice || 0,
                  description: 'Tagihan uji lab belum dibayar',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.diagnostic_summary.paid || 0,
                  description: 'Tagihan uji lab sudah dibayar',
                },
                {
                  bgColor: '#FFE0E0',
                  title:
                    this.state.diagnostic_summary.proceed_diagnostic_error || 0,
                  description: 'Proses uji lab gagal',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.diagnostic_summary.proceeded || 0,
                  description: 'Proses uji lab selesai',
                },
                {
                  bgColor: '#C5FFD5',
                  title: this.state.diagnostic_summary.done || 0,
                  description: 'Hasil lab sudah keluar',
                },
                {
                  bgColor: '#F4F4F4',
                  title: this.state.diagnostic_summary.unknown || 0,
                  description: 'Tidak diketahui',
                },
              ])}
              <EuiSpacer size='xxl' />
              <EuiSpacer size='xxl' />
              <EuiSpacer size='xxl' />
            </EuiPanel>
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Landing;
