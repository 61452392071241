import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class Laboratory {
  // Dosage
  getDiagnosticRequests = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.Order || 'DESC';
      payload.search = payload.search || '';
      let url = `${Config.apiHost}/api/v1/diagnostic-requests?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&search=${payload.search}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getDiagnosticReceiptBySession = (sessionId) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/diagnostic/receipt/${sessionId}`;
      axios
        .get(url, {
          headers: MiscService.generateHeaders(),
          responseType: 'arraybuffer',
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getDiagnosticReferralDoc = (sessionId, lang) => {
    lang = lang || 'id';
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session/lab-referral-letter/${sessionId}/${lang}`;
      axios
        .get(url, {
          headers: MiscService.generateHeaders(),
          responseType: 'arraybuffer',
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };


  // getClinics = (payload) => {
  //   return new Promise((resolve, reject) => {
  //     payload = payload || {};
  //     payload.page = payload.page || 1;
  //     payload.limit = payload.limit || 10;
  //     payload.order = payload.Order || 'DESC';
  //     payload.search = payload.search || '';
  //     let url = `${Config.apiHost}/api/clinics?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&search=${payload.search}`;
  //     if (payload.search && payload.search.length > 0) {
  //       url += `&search=${payload.search}`;
  //     }
  //     if (payload.organizationId && payload.organizationId.length > 0) {
  //       url += `&organizationId=${payload.organizationId}`;
  //     }
  //     axios
  //       .get(url, { headers: MiscService.generateHeaders() })
  //       .then((response) => {
  //         let data = camelCase(response.data)
  //         resolve(data);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //         ErrorService.handle(err);
  //       });
  //   });
  // };
}

export default Laboratory;
