import React from 'react';

import { EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const CustomBadge = (props) => {
  const { color, text, iconColor } = props;
  return (
    <EuiFlexGroup
      responsive={false}
      gutterSize='xs'
      style={{
        width: '100%',
        backgroundColor: color === null ? '#D9D9D9' : color,
        borderRadius: '10px',
        alignItems: 'center',
        paddingLeft: '8px',
        paddingRight: '8px',
      }}
    >
      <EuiFlexItem grow={4}>
        <EuiText
          textAlign='left'
          size='xs'
          style={{
            color: '#000000',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-inline-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {text}
        </EuiText>
      </EuiFlexItem>
      {iconColor !== null ? (
        <EuiFlexItem grow={1}>
          <FontAwesomeIcon icon={faCircle} color={iconColor} />
        </EuiFlexItem>
      ) : (
        <div />
      )}
    </EuiFlexGroup>
  );
};

export default CustomBadge;
