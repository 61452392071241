import React, { Component } from "react";

import {
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageContentBody,
  EuiTitle,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiButtonIcon,
} from "@elastic/eui";

import Pagination from './Pagination';

import Table from "./Table";

class TablePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      data: [],
      ObjKeys: []
    };
  }

  componentDidMount() {
  }

  render() {
    const {
      data, column, loading, onItemClick,
      page, limit, total, prev, next, toPage,
      disablePagination, sort,
    } = this.props
    return (
      <EuiPageContent>
        <EuiPageContentHeader>
          <EuiPageContentHeaderSection>
            <EuiFlexGroup>

              {this.props.backButton ? (
                <EuiButtonIcon
                  color="primary"
                  onClick={() => window.history.back()}
                  iconType="arrowLeft"
                  aria-label="Back"
                  iconSize="xl"
                  size="m"
                />
              ) : null}
              <EuiTitle>
                <h2>{this.props.title}</h2>
              </EuiTitle>
            </EuiFlexGroup>
          </EuiPageContentHeaderSection>
          <EuiPageContentHeaderSection>
            <EuiFlexGroup>
              {this.props.buttonListLabel ? (
                <EuiFlexItem grow={false}>
                  <EuiButton fill onClick={this.props.buttonListFunc}>
                    {this.props.buttonListLabel}
                  </EuiButton>
                </EuiFlexItem>
              ) : null}
              {this.props.searchComp}
              <EuiFlexItem grow={false} style={{ marginRight: "-10px" }}>
                {this.props.searchDate}
              </EuiFlexItem>
              {this.props.searchOpt}
              {this.props.buttonLabel ? (
                <EuiFlexItem grow={false}>
                  <EuiButton fill onClick={this.props.buttonFunc}>
                    {this.props.buttonLabel}
                  </EuiButton>
                </EuiFlexItem>
              ) : null}
              {this.props.buttonImportLabel ? (
                <EuiFlexItem grow={false}>
                  <EuiButton fill onClick={this.props.buttonImportFunc}>
                    {this.props.buttonImportLabel}
                  </EuiButton>
                </EuiFlexItem>
              ) : null}
              {this.props.buttonDownloadExcelLabel ? (
                <EuiFlexItem grow={false}>
                  <EuiButton fill onClick={this.props.buttonDownloadExcelFunc}>
                    {this.props.buttonDownloadExcelLabel}
                  </EuiButton>
                </EuiFlexItem>
              ) : null}
            </EuiFlexGroup>
          </EuiPageContentHeaderSection>
        </EuiPageContentHeader>
        <EuiPageContentHeader>
          {this.props.filterComponent &&
            <EuiPageContentHeaderSection>
              {this.props.filterComponent}
            </EuiPageContentHeaderSection>
          }
        </EuiPageContentHeader>
        <EuiPageContentBody>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <EuiLoadingSpinner size="l" />
            </div>
          ) : (
            <div>
              {data && data.length > 0 ?
                <Table data={data} columns={column} onItemClick={onItemClick} sort={sort} change={this.props.change ? this.props.change : () => { }} detailToggle={this.props.detailToggle || false} onExpandedRow={this.props.onExpandedRow} />
                : this.props.customEmpty ? this.props.customEmpty
                : <p style={{ textAlign: 'center' }}>Belum ada data.</p>
              }
            </div>
          )}
        </EuiPageContentBody>
        {!loading && (
          <div>
            {!disablePagination && data && data.length > 0 &&
              <Pagination
                disabled={loading}
                page={page}
                limit={limit}
                total={total}
                prev={prev}
                next={next}
                toPage={toPage}
              />
            }
          </div>
        )}
      </EuiPageContent>
    );
  }
}

export default TablePage;
