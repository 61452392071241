/* eslint-disable default-case */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";

import {
  EuiHeader,
  EuiHeaderBreadcrumbs,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiPopover,
  EuiIcon,
  EuiButton
} from "@elastic/eui";

import Config from "./Config";
import siapdok from "./assets/siapdok.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  logout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("currentUser");
    window.location = "/";
  };

  renderBreadcrumbs = () => {
    const breadcrumbs = [
      {
        text: "Level 1",
        href: "#",
        onClick: e => {
          e.preventDefault();
          console.log("You clicked management");
        },
        "data-test-subj": "breadcrumbsAnimals",
        className: "customClass"
      },
      {
        text: "Level 2",
        href: "#",
        onClick: e => {
          e.preventDefault();
          console.log("You clicked management");
        },
        "data-test-subj": "breadcrumbsAnimals",
        className: "customClass"
      }
    ];

    return (
      <EuiHeaderBreadcrumbs
        aria-label="Header breadcrumbs example"
        breadcrumbs={breadcrumbs}
      />
    );
  };

  renderLabelMode() {
    const renderText = Config.environmentLabel;

    if (renderText !== "") {
      return (<div style={{ borderRadius: 25, background: "#73AD21", color: "#fff", minWidth: 200, height: 25, marginTop: 10, marginLeft: 200, position: "absolute", paddingTop: 5 }} id="environment-label">
        {renderText}
      </div>);
    }
    return (<div />);
  }

  render() {
    let titleVariant = `Smart`;
    if (Config.variant) {
      titleVariant = `${titleVariant}${Config.variant.toUpperCase()} Admin ${Config.stage.toUpperCase()}`;
    } else titleVariant = `SiapDok! ${titleVariant} Admin`;
    return (
      <EuiHeader position={"fixed"}>
        <EuiHeaderSection>
          <EuiHeaderSectionItem border="right">
            <img
              style={{ marginLeft: 10, marginRight: 10, width: 25 }}
              className="telekonsul-menu-icon"
              src={siapdok}
              onClick={() => {
                document
                  .getElementsByClassName("euiListGroupItem__button")[0]
                  .click();
              }}
            />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
        <div style={{ marginTop: 15 }}>{titleVariant}</div>
        {this.renderLabelMode()}
        <EuiHeaderSection side="right">
          <EuiHeaderSectionItem border="left">
            <EuiHeaderSectionItemButton>
              <EuiPopover
                closePopover={() => { }}
                button={<EuiIcon type="user" />}
                onMouseEnter={() => {
                  this.setState({ profilePopup: true });
                }}
                isOpen={this.state.profilePopup}
              >
                <div
                  style={{ width: "120px" }}
                  onMouseEnter={() => {
                    this.setState({ profilePopup: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ profilePopup: false });
                  }}
                >
                  <EuiButton onClick={this.logout}>Logout</EuiButton>
                </div>
              </EuiPopover>
            </EuiHeaderSectionItemButton>
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>
    );
  }
}

export default Header;
